import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faClock} from "@fortawesome/pro-regular-svg-icons";
import SnapshotWithAi from "./eventModal/eventModalComponents/snapshotWithAi";
import {formatDateNotificationsTable} from "../../../functions/formatting/formatDate";
import mainContext from "../../contexts/mainContext";

const VehicleStoppedModal = ({}) => {


    const {trueLocation} = useContext(mainContext);



    return (
        <div>
            <div style={{width: '100%'}}>
                <SnapshotWithAi aiData={null} snapshot={{file:trueLocation?.endJpeg }} />
            </div>

            <table>
                <tbody>
                <tr>
                    <td style={{verticalAlign: 'top'}}><FontAwesomeIcon icon={faMapMarkerAlt} /></td>
                    <td style={{verticalAlign: 'top'}}>
                        { trueLocation?.endAddress?.addressData?.road &&
                            <span>{trueLocation?.endAddress?.addressData?.road}<br /></span>
                        }
                        { trueLocation?.endAddress?.addressData?.town &&
                            <span>{trueLocation?.endAddress?.addressData?.town}<br /></span>
                        }
                        { trueLocation?.endAddress?.addressData?.city &&
                            <span>{trueLocation?.endAddress?.addressData?.city}<br /></span>
                        }
                        { trueLocation?.endAddress?.addressData?.postcode &&
                            <span>{trueLocation?.endAddress?.addressData?.postcode}<br /></span>
                        }
                    </td>
                </tr>
            </tbody>
        </table>

        </div>





        // <table>
        //     <tbody>
        //     <tr style={{verticalAlign: 'top'}}>
        //         <td>
        //             <FontAwesomeIcon icon={faMapMarkerAlt}/>
        //         </td>
        //         <td>
        //             {
        //                 vehicle?.properties?.endAddress?.features[0]?.properties?.address?.road &&
        //                 <span>{vehicle.properties.endAddress?.features[0].properties.address.road}<br/></span>
        //             }
        //             {
        //                 vehicle?.properties?.endAddress?.features[0]?.properties?.address?.town &&
        //                 <span>{vehicle.properties.endAddress?.features[0].properties.address.town}<br/></span>
        //             }
        //             {
        //                 vehicle?.properties?.endAddress?.features[0]?.properties?.address?.county &&
        //                 <span>{vehicle.properties.endAddress?.features[0].properties.address.county}<br/></span>
        //             }
        //             {
        //                 vehicle?.properties?.endAddress?.features[0]?.properties?.address?.postcode &&
        //                 <span>{vehicle.properties.endAddress?.features[0].properties.address.postcode}<br/></span>
        //             }
        //         </td>
        //     </tr>
        //     </tbody>
        // </table>
    )
}

export default VehicleStoppedModal
