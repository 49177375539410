import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";


const EyesClosedStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                 style={{height, width: height, verticalAlign: 'middle'}}
                 className={"tooltipTargetEventClass" + event?._id} data-pr-position="bottom">
                <circle cx="12" cy="12" r="12" fill={'var(--surface-border)'}/>
                <path id="fatigue" style={{stroke: 'var(--text-color)', strokeWidth: 2}}
                      d="M21.0006 12.0007C19.2536 15.5766 15.8779 18 12 18M12 18C8.12204 18 4.7463 15.5766 2.99977 12.0002M12 18L12 21M19.4218 14.4218L21.4999 16.5M16.2304 16.9687L17.5 19.5M4.57812 14.4218L2.5 16.5M7.76953 16.9687L6.5 19.5"/>
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Eyes shut</Tooltip>
            }
        </div>
    )
}
export default EyesClosedStacked


