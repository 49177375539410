import React, {useContext} from 'react';
import indexContext from "../../../contexts/indexContext";
import mainContext from "../../../contexts/mainContext";


const NoDataMessage = ({showVehicleButton = true}) => {

    const {pageToShow} = useContext(indexContext);
    const {vehicle, handleVehicle} = useContext(mainContext);


    const noMediaMessage = {
        fontWeight: 'bold',
        fontSize: '18px',
        position: 'absolute',
        textAlign: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }

    const gridContainer = {
        padding: '40px 10px 10px 10px',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: '12px',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        position: 'relative',
        // marginTop: '63px',
        background: 'var(--surface-ground)'
    }

    let message;
    switch(pageToShow){
        case '/events':
            message = "No events";
            if (vehicle){
                message += ""
            }
            break;
        case '/media':
            if (vehicle){
                message = "No videos for vehicle"
            } else {
                message = "No media to show";
            }
            break;

        case '/notifications':
            message = "No alerts";
            if (vehicle){
                message += ''
            }
            break;

        case '/places':
            message = "No places of  interest set up for this fleet";
            if (vehicle){
                message += ''
            }
            break;


    }

    return (
        <div style={gridContainer}>
            <div style={noMediaMessage}>
                {message} <br /><br />
                {vehicle && showVehicleButton &&
                    <button className="p-button" style={{background: 'var(--v-list-button)', color: 'var(--v-list-button-text)'}} onClick={() => handleVehicle(null)}>
                        <span className="p-button-label">Deselect vehicle</span>
                    </button>
                }
            </div>
        </div>
    )
}
export default NoDataMessage
