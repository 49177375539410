import React, {useContext, useEffect, useState} from 'react';
import {TabMenu} from "primereact/tabmenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import indexContext from "../../../contexts/indexContext";
import {Dropdown} from "primereact/dropdown";
import {faCloudArrowUp, faFilms, faForward, faStar, faTrash, faVideo, faImage} from "@fortawesome/pro-regular-svg-icons";
import {optionTemplate} from "../../../../functions/templates/optionTemplate";
import {selectedOptionTemplate} from "../../../../functions/templates/selectedOptionTemplate";
import mainContext from "../../../contexts/mainContext";
import abovePanelContext from "../../../contexts/abovePanelContext";


const MediaFilters = ({}) => {


    const {winWidth} = useContext(indexContext);
    const {vehicle} = useContext(mainContext);
    const {handleOnlyVideoMedia, handleOnlyHlMedia, handleAllMedia, handleOnlyStarredMedia, handleOnlyReadMedia,
        handleOnlyUploads} = useContext(abovePanelContext);

    const [activeIndex, setActiveIndex] = useState();



    const itemsMobile = [
        //{display: 'All', value: 'all', index: 0, icon: faFilms},
        {display: 'Video', value: 'video', index: 0, icon: faVideo},
        {display: 'Hyperlapse', value: 'hl', index: 1, icon: faForward},
        {display: 'Pending', value: 'pending', index: 2, icon: faCloudArrowUp},
        //{display: 'Snapshot', value: 'snapshot', index: 2, icon: faImage},

        // {value: 'star', index: 4, icon: faStar},
        // {value: 'read', index: 5, icon: faTrash},
    ];


    const changeDropdownTab = async (e) => {
        const obj = {
            value: {
                value: e
            },
            index : itemsMobile.filter(item => item.value === e)[0].index
        }
        await changeTab(obj)
    }

    const changeTab = (e) => {
        setActiveIndex(e.index)
        switch (e.value.value){
            case 'video': handleOnlyVideoMedia(); break;
            case 'all': handleAllMedia(); break;
            case 'pending': handleOnlyUploads(); break;
            case 'hl': handleOnlyHlMedia(); break;
            // case 'read': handleOnlyReadMedia(); break;
            // case 'star': handleOnlyStarredMedia(); break;
        }
    }


    const selectedTypeTemplateMobile = () => {
        const test = itemsMobile.filter(item => item.index === activeIndex)[0]
        return selectedOptionTemplate(test)
    }

    useEffect(() => {
        changeTab({index:0, value: {value: "all"}})
    }, [vehicle])



    return (
        <div style={{display: 'flex', borderBottom: '1px solid var(--surface-border)', alignItems: 'center', height: '50px',
        background: 'var(--surface-ground)', paddingLeft: '8px'}}>

            {winWidth > 800 ?
                // <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => changeTab(e)}
                //          className="eventsFiltersTabs" style={{border: 'none!important', height: '48px'}}/>
                <ul className={"p-tabview-nav"} >

                    {itemsMobile.map(item => (
                        <li style={{padding: '0px 2px'}}>
                            <button className={"p-button p-button-sm eventFilterButton"} key={item.index}
                                    onClick={() => changeTab({index: item.index, value: item})}
                                    style={{
                                        background: (item.index === activeIndex) ? 'var(--v-list-button-selected)' : 'var(--v-list-button)',
                                        color: item.index === activeIndex ? 'var(--v-list-button-text-selected)' : 'var(--v-list-button-text)'
                                        ,width: "70px!important" }}>


                            <span className="p-button-label">
                                <FontAwesomeIcon icon={item.icon}/>
                                <br/>{item.display}
                            </span>
                            </button>
                        </li>
                    ))}
                </ul>

                :
                <div style={{paddingTop: '5px', height: '50px', paddingLeft: '20px'}}>
                    <Dropdown value={activeIndex} onChange={(e) => changeDropdownTab(e.value)} options={itemsMobile}
                              optionLabel="label"
                              placeholder="Select event filter" valueTemplate={selectedTypeTemplateMobile}
                              itemTemplate={(e) => optionTemplate(e)}
                              style={{height: '40px'}}/>
                </div>
            }


        </div>
    )
}
export default MediaFilters
