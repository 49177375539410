import postUpdateFleet from "../../api/postUpdateFleet";

export const subscribeToEmails = async (fleets, email, alertType, onOff) => {
    for (let i = 0; i < fleets?.length; i++) {
        let fleet = fleets[i];

        if ((!fleet?.emailList || fleet?.emailList?.length < 1) && onOff){
            fleet.emailList = [{list: alertType, email}];
            await postUpdateFleet(fleet);
        } else if (fleet?.emailList?.length >= 1 && onOff){
            // check to see if email is in current list
            const exists = fleet.emailList.filter(entry => entry.email === email && entry.type === alertType);
            if (exists?.length < 1){
                fleet?.emailList.push({list: alertType, email})
            }
            await postUpdateFleet(fleet);
        }

        if (fleet?.emailList?.length >= 1 && !onOff){
            // check to see if email is in current list

            const emailExists = fleet?.emailList.filter(listEntry => listEntry?.email === email);
            const exists = emailExists.map(function(e) { return e.list; }).indexOf(alertType);

            if (exists > -1){
                if (alertType === fleet?.emailList[exists]?.list){
                    fleet?.emailList.splice(exists, 1);
                    await postUpdateFleet(fleet);
                }
            }
        }

    }
}
