import {getStoreData} from "./db";
import {getDrivingStatus} from "./drivingStatus";

export const filterVehicleList = async (queryObject) => {
    // new filter for vehicles
    // check driving status &&
    // check value in search box &&
    // check fleet(s) selected in dropdown

    const curStoredVehicles = await getStoreData('vehicles');
    let filteredVehicles = {type: "FeatureCollection"};



    filteredVehicles.features = await curStoredVehicles.filter(v => {

        if (!queryObject?.drivingStatus.has(getDrivingStatus(v?.properties?.lastHeartbeat,
            v?.properties?.lastLocation?.ac, new Date().getTime()))) {
            return false
        }

        if (queryObject?.fleetsToShow?.size > 0 && !queryObject?.fleetsToShow.has(v.properties.fleetId)){
            return false
        }


        const foundChip = [...queryObject?.chips].filter(m => {
            // return v.properties.registration.toLowerCase().indexOf(c.toLowerCase()) >= 0
            return matchChip(v, m);
        });


        if (foundChip.length !== queryObject?.chips.size && queryObject?.chips.size > 0) {
            return false
        }

        if (queryObject?.search?.length > 0){
            return matchVehicle(v, queryObject?.search);
        }

        return true
    });
    return(filteredVehicles)
}



const matchChip = (v, m) => {
    if (typeof v?.properties?.tags === 'undefined') {
        v.properties.tags = [];
    }

    let foundTag = v?.properties?.tags?.filter(t => {
        return t?.toLowerCase() === m.toLowerCase();
    })?.length >= 1

    if (!foundTag) foundTag = v?.properties?.registration?.toLowerCase()?.includes(m.toLowerCase())
    if (!foundTag) foundTag = v?.properties?.dn?.toLowerCase()?.includes(m.toLowerCase())
    return foundTag
}


const matchVehicle = (v, m) => {


    if (typeof v.properties.tags === 'undefined') {
        v.properties.tags = [];
    }

    const foundTag = v?.properties?.tags?.filter(t => {
        return t?.toLowerCase()?.indexOf(m?.toLowerCase()) >= 0
    })

    const matchTownParked = (v) => {
        if (v?.properties?.endAddress && v?.properties?.endAddress.features && v?.properties?.endAddress?.features[0]?.properties?.address){
            if ( v?.properties?.endAddress?.features[0]?.properties?.address?.town){
                return (v?.properties?.endAddress?.features[0]?.properties?.address?.town?.toLowerCase()?.indexOf(m?.toLowerCase()) >= 0)
            } else if (v?.properties?.endAddress?.features[0]?.properties?.address?.city){
                return v?.properties?.endAddress?.features[0]?.properties?.address?.city?.toLowerCase()?.indexOf(m?.toLowerCase()) >= 0
            }
        }
    }

    const matchPostcodeParked = (v) => {
        if (v?.properties?.endAddress && v?.properties?.endAddress.features && v?.properties?.endAddress?.features[0]?.properties?.address){
            if ( v?.properties?.endAddress?.features[0]?.properties?.address?.postcode){
                return v?.properties?.endAddress?.features[0]?.properties?.address?.postcode?.toLowerCase()?.indexOf(m?.toLowerCase()) >= 0
            }

        }
    }


    // driver selected && driver
    return (
        // (displayType === 'vehicles' && v.properties?.registration?.toLowerCase()?.indexOf(m?.toLowerCase()) >= 0 )
        //
        //
        // ||
        v.properties?.registration?.toLowerCase()?.indexOf(m?.toLowerCase()) >= 0 ||
        v.properties?.driver?.toLowerCase()?.indexOf(m?.toLowerCase()) >= 0 ||
        // (displayType === 'drivers' && v.properties?.driver?.toLowerCase()?.indexOf(m?.toLowerCase()) >= 0 )
        //
        //
        // ||
        v.properties?.dn?.toLowerCase()?.indexOf(m?.toLowerCase()) >= 0 ||
        (getDrivingStatus(v?.properties?.lastHeartbeat,
            v?.properties?.lastLocation?.ac, new Date().getTime()) === 'parked' && matchTownParked(v)) ||
        (getDrivingStatus(v?.properties?.lastHeartbeat,
            v?.properties?.lastLocation?.ac, new Date().getTime()) === 'parked' && matchPostcodeParked(v)) ||
        // (v.properties.drivingStatus !== 'parked' && matchAddressDriving(v)) ||
        foundTag?.length >= 1);

}