import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";


const DistractionStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89"
                 style={{height, width: height, verticalAlign: 'middle'}}
                 className={"tooltipTargetEventClass" + event?._id} data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95" fill={'var(--surface-border)'}/>
                <path style={{fill: 'var(--text-color)'}} id="distraction"
                      d="m64.55 81.9125c-3.9-3.075-9.5625-2.3625-12.6375 1.5375s-2.3625 9.5625 1.5375 12.6375l222 174c3.9 3.075 9.5625 2.3625 12.6375-1.5375s2.3625-9.5625-1.5375-12.6375l-39.45-30.9c14.85-15.225 24.9-32.2875 29.9625-44.4 1.2375-2.9625 1.2375-6.2625 0-9.225-5.5875-13.3875-17.325-32.8875-34.875-49.1625-17.625-16.425-41.8875-30.225-72.1875-30.225-25.575 0-46.875 9.8625-63.4875 22.8l-41.9625-32.8875zm56.625 44.3625c13.575-9.6375 29.8875-16.275 48.825-16.275 24.45 0 44.55 11.1 59.9625 25.3875 14.4375 13.425 24.4125 29.3625 29.5125 40.6125-4.725 10.5-13.725 25.05-26.5875 37.8375l-20.175-15.825c3.4125-6.6 5.325-14.0625 5.325-22.0125 0-26.5125-21.4875-48-48-48-12.075 0-23.1375 4.4625-31.575 11.8125l-17.2875-13.5375zm76.9125 60.3-30.5625-23.9625c1.575-3.1875 2.475-6.825 2.475-10.6125 0-2.0625-.2625-4.0875-.75-6 .2625 0 .4875 0 .75 0 16.575 0 30 13.425 30 30 0 3.7125-.675 7.275-1.9125 10.575zm3.525 48.8625c-9.5625 4.0875-20.1 6.5625-31.6125 6.5625-24.45 0-44.55-11.1-59.9625-25.3875-14.4375-13.425-24.4125-29.3625-29.5125-40.6125 3.1125-6.9 8.0625-15.5625 14.775-24.3l-14.1375-11.1375c-8.55 11.1375-14.6625 22.2375-18.225 30.825-1.2375 2.9625-1.2375 6.2625 0 9.225 5.5875 13.3875 17.325 32.8875 34.875 49.1625 17.625 16.425 41.8875 30.225 72.1875 30.225 17.925 0 33.7125-4.8375 47.325-12.1875l-15.7125-12.375zm-79.6125-59.4375c0 26.5125 21.4875 48 48 48 4.9875 0 9.7875-.75 14.325-2.175l-21.075-16.575c-8.8125-2.025-16.1625-7.95-20.1375-15.8625l-21.0375-16.575c-.075 1.05-.1125 2.1-.1125 3.1875z"/>
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Distraction</Tooltip>
            }
        </div>
    )
}
export default DistractionStacked
