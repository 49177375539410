import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const PedestrianStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="pedestrian" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id} style={{height, width: height, verticalAlign: 'middle'}}  data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95" fill={'var(--surface-border)'}/>
                <path fill="var(--text-color)" d="m174.84,76.11c0-12.58,10.2-22.78,22.78-22.78s22.78,10.2,22.78,22.78-10.2,22.78-22.78,22.78-22.78-10.2-22.78-22.78Zm-15.9,71.83c-.47.19-.9.38-1.38.57l-3.8,1.66c-7.78,3.46-13.76,10.06-16.47,18.13l-1.1,3.3c-2.47,7.42-10.09,12.11-17.73,10.43-9-1.97-14.04-11.17-11.21-19.67l1.23-3.7c5.41-16.18,17.37-29.38,32.94-36.3l3.8-1.66c9.87-4.37,20.55-6.64,31.37-6.64,21.17,0,40.24,12.72,48.36,32.22l7.31,17.51,9.8,4.9c6.97,3.48,10.53,11.65,7.83,18.96-3.2,8.64-13.04,12.37-21.04,8.37l-12.72-6.31c-4.89-2.47-8.73-6.55-10.82-11.63l-4.56-10.92-9.16,31.08,23.49,25.63c2.56,2.8,4.37,6.17,5.32,9.87l10.92,43.71c2.04,8.12-2.89,16.37-11.06,18.41-8.16,2.04-16.37-2.89-18.41-11.06l-10.44-41.81-33.55-36.59c-7.02-7.64-9.63-18.32-6.98-28.33l8.02-30.14h.05Zm-27.43,94.26l11.86-29.61c1,1.42,2.14,2.75,3.32,4.08l19.31,21.07-6.88,17.18c-1.14,2.85-2.85,5.46-5.03,7.64l-29.28,29.28c-5.93,5.93-15.57,5.93-21.5,0-5.93-5.93-5.93-15.57,0-21.5l28.19-28.14Z"/>
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Pedestrian Warning</Tooltip>
            }
        </div>
    )
}
export default PedestrianStacked
