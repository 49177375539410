import React, {useContext, useEffect, useState} from 'react';
import ReportsDatePicker from "./reportsDatePicker";
import {Dropdown} from "primereact/dropdown";
import indexContext from "../../../contexts/indexContext";
import mainContext from "../../../contexts/mainContext";
import reportsContext from "../../../contexts/reportsContext";
import {fetchAdminFleets} from "../../../../functions/fetchAdminFleets";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";

const ReportsParameterSelection = ({handleGenerateReport}) => {


    const {fleets} = useContext(indexContext);
    const {vehicle, vehicles} = useContext(mainContext)
    const {dateRange, reportType, handleDateRange, theFleet, handleTheFleet, theVehicle, handleTheVehicle} = useContext(reportsContext)
    const [fleetsDropdown, setFleetsDropdown] = useState([]);

    useEffect(async () => {
        if (fetchUserLevel() === 'fleet'){
            const fleetsAllowed = fetchAdminFleets();
            const filtered = await fleets.filter(fleet => fleetsAllowed?.includes(fleet?.fleetId));
            setFleetsDropdown(filtered)

            if (filtered?.length === 1){
                handleTheFleet(filtered?.[0])
            }


        } else {
            setFleetsDropdown(fleets)
        }


    }, [fleets])


    return (
        <React.Fragment>
            <ReportsDatePicker handleDateRange={handleDateRange} dateRange={dateRange}/>
            <br/>


            {reportType?.type?.includes('fleet') && fleetsDropdown?.length > 1 ?
                <Dropdown options={fleetsDropdown} optionLabel="fleetName" filter filterBy="fleetName"
                          style={{
                              height: '46px',
                              width: '202px',
                              border: 'none',
                              borderRadius: '0px',
                              lineHeight: '35px',
                              marginRight: '10px'
                          }}
                          placeholder="Select fleet" value={theFleet}
                          onChange={(e) => {
                              handleTheFleet(e.value)
                          }}
                />
            :
                <React.Fragment>{theFleet}</React.Fragment>

            }

            {reportType?.type?.includes('vehicle') &&
                <Dropdown options={vehicles?.features} optionLabel="properties.registration" filter
                          filterBy="properties.registration"
                          style={{
                              height: '46px',
                              width: '202px',
                              border: 'none',
                              borderRadius: '0px',
                              lineHeight: '35px',
                              marginRight: '10px'
                          }}
                          placeholder="Select vehicle" value={theVehicle}
                          onChange={(e) => handleTheVehicle(e.value)}
                />

            }

            <br/><br/>



            <button className="p-button p-button-sm " onClick={() => handleGenerateReport(true)}
                    disabled={(reportType?.type?.includes('vehicle') && !theVehicle ||
                        reportType?.type?.includes('fleet') && !theFleet)}
                    style={{background: 'var(--v-list-button-selected)', color: 'var(--v-list-button-text-selected)'}}
            >
                Generate Report
            </button>

        </React.Fragment>
    )
}

export default ReportsParameterSelection
