import React, {useContext, useEffect, useState, useRef} from 'react';
import AbovePanel from "../../abovePanelComponents/abovePanel";
import {mediaGridData} from "./gridFunctions/mediaGridData";
import {eventsGridData} from "./gridFunctions/eventsGridData";
import EventGridItem from "./events/eventGridItem";
import indexContext from "../../../contexts/indexContext";
import abovePanelContext from "../../../contexts/abovePanelContext";
import gridContext from "../../../contexts/gridContext";
import NotificationGridItem from "./notifications/notificationGridItem";
import postNotificationRead from "../../../../api/notifications/postNotificationRead";
import mainContext from "../../../contexts/mainContext";
import postMarkEventRead from "../../../../api/events/postMarkEventRead";
import LoadingSpinner from "../../loadingSpinner";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {faStar, faTrash, faTrashSlash} from "@fortawesome/pro-light-svg-icons";
import {faStar as solidStar} from "@fortawesome/pro-solid-svg-icons";
import EventModalParent from "../../modals/eventModal/eventModalParent";
import DeviceDetailsModalParent from "../../modals/deviceDetailsModal/deviceDetailsModalParent";
import {handleNotificationStaring} from "./gridFunctions/handleNotificationStaring";
import NoDataMessage from "./noDataMessage";
import {handleNotificationReadV2} from "./gridFunctions/handleNotificationRead";
import {ConfirmPopup, confirmPopup} from "primereact/confirmpopup";
import {TOAST_DURATION} from "../../../../Constants";
import {filterVehicleList} from "../../../../functions/vListFilterFunc";
import ViewOnEventsMap from "./events/viewOnEventsMap";
import fetchNotifications from "../../../../api/notifications/fetchNotifications";
import EventParamChips from "./events/eventParamChips";
import {getStoreData} from "../../../../functions/db";
import fetchMediaQueue from "../../../../api/media/fetchMediaQueue";


const gridContainer = {
    padding: '40px 10px 10px 10px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '12px',
    width: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: '63px',
    background: 'var(--surface-ground)'
}

const gridItem = {
    border: 'solid 1px var(--surface-border)',
    borderRadius: '5px',
    padding: '10px 0 10px 0!important',
    // boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)'
}

const noMediaMessage = {
    fontWeight: 'bold',
    fontSize: '18px',
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}

const Grid = ({}) => {

    const {vehicle, vehiclesFilter, date, handleDisableDateChange, handleDate,
        sideBarWidth, fleetsToShow, pageToShow, handleVideoToShow, handleEvent, closeModal,
        vehicleFilterParams, toast, disableDateChange} = useContext(mainContext);

    const {winWidth, handleNotiCount, fleets} = useContext(indexContext);


    const [tagLib, setTagLib] = useState();
    const [gridData, setGridData] = useState(null);
    //to be used to keep chart on events filters showing for all, not just in the grid

    const [eventParams, setEventParams] = useState([]);
    const [eventCounts, setEventCounts] = useState([])

    //to sort later
    const [unreadEvents, setUnreadEvents] = useState(true);


    const [starredNotis, setStarredNotis] = useState(false);
    const [readNotis, setReadNotis] = useState(false);
    const [pageMounted, setPageMounted] = useState(false);
    const [selected, setSelected] = useState(new Set([]));
    const [action, setAction] = useState();
    const [selectAll, setSelectAll] = useState();
    const [gridDate, setGridDate] = useState(null);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [starred, setStarred] = useState([]);
    const [viewed, setViewed] = useState([]);
    const [viewMode, setViewMode] = useState('grid');
    const [dateSortEvents, setDateSortEvents] = useState(true);
    const [scoreSortEvents, setScoreSortEvents] = useState(false);
    const [showEventFilters, setShowEventFilters] = useState(false);
    const [lastDaysOfEvents, setLastDaysOfEvents] = useState(7);
    const [notiParams, setNotiParams] = useState();
    const [loading, setLoading] = useState(true);
    const [comparatorData, setComparatorData] = useState([]);

    const handleShowEventFilters = () => setShowEventFilters(!showEventFilters);
    const handleLastDaysOfEvents = (val) => setLastDaysOfEvents(val);



    const actionOptions = [
        {val: 'read', display: 'Delete', icon: faTrash},
        {val: 'unread', display: 'Undelete', icon: faTrashSlash},
        {val: 'star', display: 'Star', icon: solidStar},
        {val: 'unstar', display: 'Unstar', icon: faStar},
    ];


    const handleDateSortEvents = async () => {
        setDateSortEvents(true);
        setScoreSortEvents(false);
        handleEventParams(JSON.parse(JSON.stringify(eventParams)))
    }

    const handleScoreSortEvents = async () => {
        setDateSortEvents(false);
        setScoreSortEvents(true);
        handleEventParams(JSON.parse(JSON.stringify(eventParams)))
    }



    const handleViewMode = (val) => setViewMode(val)

    const handleSetViewed = (arr) => setViewed(arr);
    const handleSetStarred = (arr) => setStarred(arr);

    const handleEventParams = (params) => setEventParams(params)

    useEffect(async () => {
        if (pageToShow === '/events'){
            setLoading(true)
            const data = await eventsGridData(eventParams, handleDisableDateChange, vehicle,
                vehicleFilterParams?.fleetsToShow, gridDate, 0, dateSortEvents, scoreSortEvents, fleets, lastDaysOfEvents);


            if(!unreadEvents){
                const read = data.reduce((acc, curVal) => acc.concat(curVal._id), [])
                handleSetViewed(read)
            }


            applyEventsGridData(data.result);
            setEventCounts(data?.resultCounts);
           
            setComparatorData(data?.countsDns);
            setLoading(false)
        }
    }, [eventParams]);



    // mark notification read/unread
    const handleNotificationRead = async (event, notification, type = 'read') => {
        const str = 'Are you sure you want to '+ (type === 'read' ? 'delete' : 'undelete')+ ' this '+
            (notification?.notificationDetails ? 'alert' : 'event') +'?';

        confirmPopup({
            target: event.currentTarget,
            message: str,
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await handleNotificationReadV2(notification, type, handleNotiCount, toast, closeModal);
            },
            reject: () => {
                return
            }
        });

    }

    // mark notification starred/unstarred
    const handleNotificationStarred = async (notification) => {
        await setStarred(await handleNotificationStaring(starred, notification, toast));
    }


    const handleSuperTags = async (params) => {
        //     const data = await eventsGridData(params, handleDisableDateChange, vehicle, vehicleFilterParams?.fleetsToShow, date);
        // applyEventsGridData(data.result);
        // setEventCounts(data.resultCounts);
    }


    //set date for grid independant of main date
    const handleGridDate = (e, offset = 0) => {
        let nd = null;
        if (e){
            nd = new Date(e);
            nd.setDate(e.getDate() + offset);
            handleDate(nd);
        }

        setGridDate(nd);
    }

    // change dropdown option
    const handleAction = (val) => {
        setAction(val);
    }

    // toggle uploads only
    const handleOnlyUploads = async () => {
        await setGridData(null);
        const data = await fetchMediaQueue(notiVariables('queue'));
        setGridData(data);

        // const data = await mediaGridData(['snapshot'], handleDisableDateChange, vehicle, vehicleFilterParams?.fleetsToShow,
        //     gridDate, gridData?.length, dateSortEvents, scoreSortEvents, fleets, 30);
        // setGridData(data.result);
    }


    // toggle video only
    const handleOnlyVideoMedia = async () => {
        setGridData(null);
        const data = await mediaGridData(['video'], handleDisableDateChange, vehicle, vehicleFilterParams?.fleetsToShow,
            gridDate, gridData?.length, dateSortEvents, scoreSortEvents, fleets, 30);
        setGridData(data.result);
    }

    // toggle hl only
    const handleOnlyHlMedia = async () => {
        setGridData(null);

        const data = await mediaGridData(['hyperlapse'], handleDisableDateChange, vehicle, vehicleFilterParams?.fleetsToShow,
            gridDate, 0, dateSortEvents, scoreSortEvents, fleets, 30);
        setGridData(data);

    }

    // toggle starred only
    const handleOnlyStarredMedia = async () => {
        setGridData(null);
        const data = await mediaGridData(eventParams, handleDisableDateChange, vehicle, vehicleFilterParams?.fleetsToShow,
            gridDate, gridData?.length, dateSortEvents, scoreSortEvents, fleets, 30);
        setGridData(data.result);
    }
    // toggle read only
    const handleOnlyReadMedia = async () => {
        setGridData(null);
        const data = await mediaGridData(eventParams, handleDisableDateChange, vehicle, vehicleFilterParams?.fleetsToShow,
            gridDate, gridData?.length, dateSortEvents, scoreSortEvents, fleets, 30);
        setGridData(data.result);
    }

    // toggle all
    const handleAllMedia = async () => {
        setGridData(null);
        const data = await mediaGridData(eventParams, handleDisableDateChange, vehicle, vehicleFilterParams?.fleetsToShow,
            gridDate, gridData?.length, dateSortEvents, scoreSortEvents, fleets, 30);
        setGridData(data.result);
    }



    const fetchNotificationsData = async (refresh = true, params ={
        'notificationRead.email': { "$not": { "$all": [localStorage.getItem('email')] } },
        'notificationType': {"$in": ["modeChanged", "health"]},
        fleetId : {"$in": fleets?.reduce((acc, curVal) => acc.concat(curVal.fleetId), [])}
    }, skip = 0, limit = 50) => {
        if (fetchUserLevel() !== 'superuser'){
            params.mflGroup = localStorage.getItem('mflGroup');
        }

        params.skip = skip;
        params.limit = limit;

        const r = await fetchNotifications(params);
        return r;
    }
















    const handleStarredNotis = async() => {
        setStarredNotis(true);
        setReadNotis(false)
        const r = await fetchNotificationsData(true, notiVariables('notification', true, false,
            {"$in": ["modeChanged", "health"]}));
        setGridData(r);
    }

    const handleReadNotis = async () => {
        setStarredNotis(false);
        setReadNotis(true)
        const r = await fetchNotificationsData(true, notiVariables('notification', false, true,
            {"$in": ["modeChanged", "health"]}));
        setGridData(r);
    }

    const handleAllNotis = async () => {
        setStarredNotis(false);
        setReadNotis(false);
        setNotiParams({"$in": ["modeChanged", "health"]})
        const r = await fetchNotificationsData(true, notiVariables('notification', false, false,
            {"$in": ["modeChanged", "health"]}));
        setGridData(r);
    }

    const handleNotiType = async (type) => {
        setNotiParams({"$in": [type]})
        setStarredNotis(false);
        setReadNotis(false);

        const r = await fetchNotificationsData(true, notiVariables('notification', false, false, {"$in": [type]}));
        setGridData(r);
    }


    // select all notifications
    const handleSelectAll = () => {
        let ids
        if(selectAll){
            ids = new Set([])
        } else {
            ids = gridData.reduce((acc, curVal) => new Set([...acc, curVal._id]), new Set([]))
        }
        setSelected(ids)
        setSelectAll(!selectAll);
    }



    // return notification variables to send
    const notiVariables = (type, starredNotis, readNotis, query = {'$in': ['modeChanged', 'health']}) => {
        let dataToSend = {}




        if (!vehicle){
            if (vehicleFilterParams?.fleetsToShow?.size === 0){
                dataToSend = {
                    fleetId : {"$in": fleets?.reduce((acc, curVal) => acc.concat(curVal.fleetId), [])},
                    // dn: {"$in" : vehiclesFilter?.features.reduce((acc, curVal) => acc.concat(curVal.properties.dn), [])}
                }
            } else {
                dataToSend = {
                    fleetId: {"$in": [...vehicleFilterParams?.fleetsToShow]},
                    // dn: {"$in" : vehiclesFilter?.features.reduce((acc, curVal) => acc.concat(curVal.properties.dn), [])}
                }
            }
        } else {
            dataToSend = {
                dn: vehicle.properties.dn,
                // fleetId : {"$in": fleets?.reduce((acc, curVal) => acc.concat(curVal.fleetId), [])}
            }
        }


        if (type === 'notification'){
            dataToSend.notificationType = query;
            // if (starredNotis){
            //     dataToSend['starredBy'] = {"$in" : [localStorage.getItem('email')]}
            // } else {
                if (readNotis){
                    dataToSend['notificationRead'] = {"$elemMatch" : {email: localStorage.getItem('email')}}
                } else {
                    dataToSend['notificationRead.email'] = { "$not": { "$all": [localStorage.getItem('email')] } }
                }
            // }
        }

        if (fetchUserLevel() !== 'superuser'){
            dataToSend.mflGroup = localStorage.getItem('mflGroup');
        }

        if (type === 'queue') {
            var threeMonthsAgo = new Date();
            threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 7);
            dataToSend.requestDate = {"$gte" : threeMonthsAgo};
            dataToSend.durationMinutes = {'$exists': false}

        }

        return dataToSend
    }





    // add notification to selected array
    const addRemoveSelected = (noti) => {
        if (selected.has(noti)){
            selected.delete(noti)
            setSelected(new Set([...selected]));
        } else {
            setSelected(new Set([...selected, noti]));
        }
    }

    const markSelectedRead = () => {

        let message;
        const newViewed = [...viewed];



        if (pageToShow === '/notifications'){
            if (readNotis){
                handleNotiCount(gridData?.length);
                message = `${gridData?.length} alerts undeleted`;
            } else {
                handleNotiCount(-gridData?.length);
                message = `${gridData?.length} alerts deleted`;
            }
        } else {
            message = `${gridData?.length} Events ${readNotis ? 'undeleted' : 'deleted'}`;
        }

        gridData.reduce((acc, curVal) => acc.concat(curVal._id), []).forEach(async item => {
            if(viewed.includes(item)){
                newViewed.splice(newViewed.indexOf(item), 1)
            } else {
                newViewed.push(item)
            }

            if (pageToShow === '/notifications'){
                await postNotificationRead({notification: {_id: item}, user: localStorage.getItem('email')});

            } else {
                await postMarkEventRead({
                    id: item,
                    dn: gridData.filter(data => data?._id === item)?.[0]?.dn,
                    unread: readNotis
                });
            }
            // remove element
            const ele = document.getElementById(item);
            ele?.parentNode?.remove();
        });


        toast.current.show({ severity: 'success', summary: 'All done', detail: message,
            life: TOAST_DURATION });

       setGridData([])
        // setTimeout(() => refresh(), 100);
    }

    // const markSelectedRead = () => {
    //
    //     let message;
    //
    //     if (action.val === 'read' || action.val === 'unread'){
    //
    //
    //         const newViewed = [...viewed];
    //
    //         if (action.val === 'read') {
    //             handleNotiCount(-selected?.size);
    //             message = `${selected?.size} alerts deleted`;
    //         } else {
    //             handleNotiCount(selected?.size);
    //             message = `${selected?.size} alerts undeleted`;
    //         }
    //
    //         selected.forEach(async item => {
    //
    //             if(viewed.includes(item)){
    //                 newViewed.splice(newViewed.indexOf(item), 1)
    //             } else {
    //                 newViewed.push(item)
    //             }
    //
    //             if (pageToShow === '/notifications'){
    //                 await postNotificationRead({notification: {_id: item}, user: localStorage.getItem('email')});
    //
    //             } else {
    //                 await postMarkEventRead({
    //                     id: item,
    //                     dn: gridData.filter(data => data?._id === item)?.[0]?.dn,
    //                     unread: action.val === 'unread'
    //                 });
    //             }
    //             // remove element
    //             const ele = document.getElementById(item);
    //             //ele?.parentNode?.remove()
    //         });
    //
    //
    //
    //         handleSetViewed(newViewed);
    //     } else {
    //
    //         let newStarred = [...starred];
    //         message = `${selected?.size} stars toggled`;
    //
    //         try{
    //             selected.forEach(async item => {
    //                 if(starred.includes(item)){
    //                     newStarred.splice(newStarred.indexOf(item), 1)
    //                 } else {
    //                     newStarred.push(item)
    //                 }
    //
    //                 if (pageToShow === '/notifications'){
    //                     await postNotificationStarred({
    //                         notification: {_id: item},
    //                         user: localStorage.getItem('email')
    //                     });
    //                 } else {
    //                     await postMarkEventStarred({
    //                         id: item,
    //                         dn: gridData.filter(data => data?._id === item)?.[0]?.dn,
    //                         star: action
    //                     });
    //                 }
    //             });
    //         } catch (e){
    //
    //         }
    //         handleSetStarred(newStarred);
    //     }
    //
    //     toast.current.show({ severity: 'success', summary: 'All done', detail: message,
    //         life: TOAST_DURATION });
    //
    //     setSelectAll(false);
    //     setSelected(new Set([]));
    //     setTimeout(() => refresh(), 100);
    //     // TODO ensure tab is consistant with whats shown
    // }


    const groupNotifications = (originalNotifications, newNotifications) => {

    }



    // fetch more notifications here and append the same as events
    const loadMore = async () => {

        let og = gridData;

        if (gridData?.[0]?.media_id) return;

        if (og?.length >= 50) {
            if (pageToShow === '/notifications'){
                const r = await fetchNotificationsData(true, notiVariables('notification', starredNotis, readNotis, notiParams), gridData?.length, 50);

                // group by matching tags per dn

                setGridData(og.concat(r));
            } else {
                if(gridData?.[0]?.notificationType){
                    const data = await mediaGridData(['hyperlapse'], handleDisableDateChange, vehicle, vehicleFilterParams?.fleetsToShow,
                        gridDate, gridData?.length, dateSortEvents, scoreSortEvents, fleets, 30);
                    setGridData(og.concat(data));
                } else if (gridData?.[0]?.requestDate) {
                    // todo
                    console.log('uploads')
                }
                else {
                    const data = await eventsGridData( pageToShow === '/events' ? eventParams : ['video', 'user'], handleDisableDateChange, vehicle, vehicleFilterParams?.fleetsToShow,
                        gridDate, gridData?.length, dateSortEvents, scoreSortEvents, fleets, lastDaysOfEvents);
                    setGridData(og.concat(data.result));
                }
            }
        }
        await new Promise(r => setTimeout(r, 500));
    }

    const applyEventsGridData = async  (data) => {
        setGridData(data);

        if (data?.length > 0){
            const starredArr = [];

            data?.forEach(curVal => {
                if (curVal.starredBy && curVal?.starredBy?.includes(localStorage.getItem('email'))) {
                    starredArr.push(curVal._id)
                }
            });

            handleSetStarred(starredArr);
        }

    }



    // refresh of grid data
    const refresh = async () => {

        setLoading(true);

        setGridData(null);
        let data;
        let vs = await filterVehicleList(vehicleFilterParams);

        if (pageToShow === '/media'){
            data = await mediaGridData(['video'], handleDisableDateChange, vehicle, vehicleFilterParams?.fleetsToShow,
                gridDate, gridData?.length, dateSortEvents, scoreSortEvents, fleets, 30);

            setGridData(data.result);
        }

        if (pageToShow === '/events'){
            setGridData([])
            const data = await eventsGridData(eventParams, handleDisableDateChange, vehicle,
                vehicleFilterParams?.fleetsToShow, gridDate, 0, dateSortEvents, scoreSortEvents, fleets, lastDaysOfEvents);

            if(!unreadEvents){
                const read = data.reduce((acc, curVal) => acc.concat(curVal._id), [])
                handleSetViewed(read)
            }
            applyEventsGridData(data.result);
            setEventCounts(data.resultCounts);
            setComparatorData(data?.countsDns);

        }

        if (pageToShow === '/notifications'){
           const r = await fetchNotificationsData(true, notiVariables('notification', false, false));
            setGridData(r)
        }
        setTimeout(() => {setLoading(false)}, 1000)
    }

    // clear interval on page load for map vehicles
    useEffect(async () => {
        setPageMounted(true);


        const libRaw = await getStoreData('eventTags');

        let lib  ={};

        for (let i = 0; i < libRaw?.length; i++) {
            lib[libRaw[i].tag] = libRaw[i];
        }
        setTagLib(lib)

        return () => setPageMounted(false);
    }, []);



    useEffect( async () => {
        // in here if vehicle is same as url params, dont refresh
        // init refresh to include url params

        if (pageMounted){
            await refresh()
        }

    }, [pageMounted, vehicle, pageToShow,  gridDate, date, vehicleFilterParams?.fleetsToShow, lastDaysOfEvents]);


    useEffect(() => {
        if(gridData && gridData?.length > 0 && (pageToShow === '/notifications' || pageToShow === '/media') ) {
            const starredArr = [];

            gridData.forEach(curVal => {
                if (curVal.starredBy && curVal?.starredBy?.includes(localStorage.getItem('email'))) {
                    starredArr.push(curVal._id)
                }
            });

            handleSetStarred(starredArr);
        }
    }, [gridData])



    const GridRow = (data) => {

        return (
            <React.Fragment>
                {(pageToShow === '/events' || pageToShow === '/media') && data?._id &&
                    <div style={{width:'100%'}} key={data?._id} id={data?._id} className="gridCard">
                        <div style={{padding: '15px'}}>
                            <div style={gridItem} className="surface-card">
                                {data?.notificationType ?
                                    <NotificationGridItem notification={data} handleVideoToShow={handleVideoToShow} selected={selected} />
                                :

                                    <React.Fragment>
                                        {(data?.media_id || data?.filename) ?
                                            <NotificationGridItem notification={data} handleVideoToShow={handleVideoToShow} selected={selected} />

                                        :
                                            <EventGridItem event={data} handleNotificationRead={handleNotificationRead} unreadEvents={unreadEvents}
                                                       handleEvent={handleEvent} handleNotificationStarred={handleNotificationStarred}
                                                       addRemoveSelected={addRemoveSelected} selected={selected} starred={starred}
                                                       eventCounts={eventCounts}/>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                }
                {pageToShow === '/notifications' &&
                    <div style={{width:'100%'}} key={data?._id} id={data?._id} className="gridCard">
                        <div style={{padding: '15px'}}>
                            <div className="surface-card" style={gridItem}>
                                <NotificationGridItem notification={data} handleVideoToShow={handleVideoToShow} selected={selected} />
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }


    const handleGridData = (data) => {
        setGridData(data);
    }

    const removeParam = (val) =>{
        const copy = [...eventParams]
        const index = copy?.indexOf(val);
        if (index > -1) copy.splice(index, 1)
        handleEventParams(copy)
    }

    return (
        <React.Fragment>


            <abovePanelContext.Provider value={{sideBarWidth, readNotis, handleReadNotis,
                 handleStarredNotis, starredNotis, handleAction, action,markSelectedRead,
                handleSelectAll, selectAll,  unreadEvents,
                handleAllMedia, handleOnlyHlMedia, handleOnlyVideoMedia, refresh, gridData, handleGridData, handleGridDate,
                gridDate, handleOnlyReadMedia, handleOnlyStarredMedia, handleAllNotis,
                handleOnlyUploads, handleSuperTags, handleNotiType, actionOptions, eventParams,
                handleEventParams, disableDateChange, handleDisableDateChange, eventCounts, handleViewMode, viewMode,
                handleDateSortEvents, handleScoreSortEvents, dateSortEvents, scoreSortEvents, handleShowEventFilters

            }}>
                <AbovePanel lastDaysOfEvents={lastDaysOfEvents} handleLastDaysOfEvents={handleLastDaysOfEvents}/>

            </abovePanelContext.Provider>




            <gridContext.Provider value={{starred, readNotis, addRemoveSelected, handleNotificationStarred,
                handleNotificationRead, selected, gridData,  eventParams, handleEventParams, disableDateChange,
                handleDateSortEvents, handleScoreSortEvents, dateSortEvents, scoreSortEvents, eventCounts, handleShowEventFilters,
                removeParam,  tagLib, loading, comparatorData}}>






                {pageToShow === '/events' && winWidth > 800 &&
                    // <EventCheckboxes  />
                    <EventParamChips />
                }





                {viewMode === "grid" ?
                    <React.Fragment>
                        {loading &&
                            <div style={gridContainer}  className="mapContainer">
                                <div style={noMediaMessage}>
                                    {/*<ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="4" animationDuration="1s"/>*/}
                                    <LoadingSpinner />
                                    <br />
                                    Loading...
                                </div>
                            </div>
                        }


                        {gridData?.length === 0 && !loading &&
                            <NoDataMessage />
                        }

                        {/*{(gridData?.length > 0 && pageToShow !== '/events') &&*/}
                        {/*    <div style={winWidth > 800 ? {width: '100%', height: '100%', marginTop: '92px'} : {width: '100%', height: '100%',}}>*/}
                        {/*        <DataScroller value={gridData} itemTemplate={GridRow} rows={12} inline*/}
                        {/*                      scrollHeight={winWidth > 800 ? "77vh" : "84vh"} className="scrollerTest"  />*/}
                        {/*    </div>*/}
                        {/*}*/}


                        {/*{(gridData?.length > 0  && pageToShow === '/events') &&*/}
                            <React.Fragment>
                                {!loading &&
                                    <div style={{
                                        width: '100%',
                                        height: '100%',
                                        marginTop: winWidth > 800 ? pageToShow === '/media' ? '100px' : (eventParams?.length > 0) ?  pageToShow === '/notifications' ? '100px' :'0px' : '100px' : '0px',
                                        overflowY: 'scroll',
                                        overflowX: 'hidden',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        paddingBottom: winWidth > 800 ? '0px' : '100px',
                                        background: 'var(--surface-ground)'
                                    }}

                                         onScroll={async (e) => {

                                             if (showLoadMore && (e.nativeEvent.target.scrollTop / e.nativeEvent.target.scrollHeight) * 100 < 70) {
                                                 await setShowLoadMore(false)

                                             }
                                             if (!showLoadMore && ((e.nativeEvent.target.scrollTop / e.nativeEvent.target.scrollHeight) * 100 > 70)) {
                                                 await setShowLoadMore(true)
                                                 await loadMore();
                                             }
                                         }}>
                                        {gridData && gridData?.length > 0 && gridData?.map(item => (
                                            <div className="eventGridContainer" key={item?._id} id={item?._id}>
                                                {GridRow(item)}
                                            </div>
                                        ))}
                                    </div>


                                }
                            </React.Fragment>
                        {/*}*/}
                    </React.Fragment>
                    :
                    <ViewOnEventsMap/>

                }


                <EventModalParent/>
                <DeviceDetailsModalParent/>

                <ConfirmPopup/>


            </gridContext.Provider>
        </React.Fragment>
    )
}
export default Grid
