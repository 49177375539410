import React, {useContext, useEffect, useState} from 'react';
import {Colours} from "../../../../colours";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlassPlus, faMagnifyingGlassMinus, faCube, faExclamationCircle,
faTrafficLight, faSatellite} from "@fortawesome/pro-regular-svg-icons";
import indexContext from "../../../contexts/indexContext";


const ReactMapControls = ({map, changeStyle, handleLiveTrack,  parent = 'map'}) => {


    const [fullscreen, setFullscreen] = useState(false);
    const [satelliteImagery, setSatelliteImagery] = useState(false);
    const [traffic, setTraffic] = useState(false);
    const [events, setEvents] = useState(true);
    const [threeD, setThreeD] = useState(false);
    const [jLine, setJLine] = useState(true);


    const exitHandler = () => {
        setFullscreen(false)
    }

    const {darkMap, darkMode} = useContext(indexContext)

    const resetStyle = () => {
        if(map){
            if (darkMap){
                changeStyle('fleetfocus/cl74ogqly001b14n15u5x24zn')
            } else {
                changeStyle('fleetfocus/clyreqdlj00d701qr81uf1gsw')
            }
        }
    }

    useEffect(() => {
        if (map){
            resetStyle();
        }
    }, [darkMap, darkMode])


    document.addEventListener('webkitfullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);




    return (
        <div className={"mapboxgl-ctrl-group mapboxgl-ctrl"}>


            {/*<button onClick={() => {*/}

            {/*    if (fullscreen) {*/}
            {/*        document.exitFullscreen();*/}
            {/*    } else {*/}
            {/*        document.getElementsByClassName('mapContainer')[0]?.requestFullscreen();*/}
            {/*        setFullscreen(true)*/}
            {/*    }*/}
            {/*}}>*/}
            {/*    FS*/}
            {/*</button>*/}


            <button onClick={() => {
                if (!satelliteImagery) changeStyle('fleetfocus/clyzli91b00ds01qn5p0yb26x');
                else resetStyle();
                setSatelliteImagery(!satelliteImagery)
            }} style={satelliteImagery ? {background: '#b4b6ba', color: 'black'} : {color: 'black'}}>
                <FontAwesomeIcon icon={faSatellite} style={{fontSize: '16px'}} />
            </button>


            <button onClick={() => map.zoomIn({duration: 500})} style={{color: 'black'}}>
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} style={{fontSize: '16px'}}/>
            </button>
            <button onClick={() => map.zoomOut({duration: 500})} style={{color: 'black'}}>
                <FontAwesomeIcon icon={faMagnifyingGlassMinus} style={{fontSize: '16px'}} />
            </button>

            {parent === 'map' &&
                <React.Fragment>
                    <button onClick={() => {
                        if (traffic) {

                            map.setPaintProperty('traffic-low', 'line-opacity', 0);
                            map.setPaintProperty('traffic-heavy', 'line-opacity', 0);
                            map.setPaintProperty('traffic-moderate', 'line-opacity', 0);
                            map.setPaintProperty('traffic-severe', 'line-opacity', 0);
                        } else {
                            map.setPaintProperty('traffic-low', 'line-opacity', 1);
                            map.setPaintProperty('traffic-heavy', 'line-opacity', 1);
                            map.setPaintProperty('traffic-moderate', 'line-opacity', 1);
                        }
                        setTraffic(!traffic)

                    }} style={traffic ? {background: '#b4b6ba', color: 'black'} : {color: 'black'}}>
                        <FontAwesomeIcon icon={faTrafficLight} style={{fontSize: '16px'}}/>
                    </button>


                    <button onClick={() => {
                        if (!events) {
                            map.setLayoutProperty('events-layer', 'visibility', 'visible');
                        } else {
                            map.setLayoutProperty('events-layer', 'visibility', 'none');
                        }
                        setEvents(!events)
                    }} style={events ? {background: '#b4b6ba', color: 'black'} : {color: 'black'}}>
                        <FontAwesomeIcon icon={faExclamationCircle} style={{fontSize: '16px'}}/>
                    </button>

                    <button onClick={() => {
                        if (!threeD) {
                            handleLiveTrack(true);
                        } else {
                            handleLiveTrack(false);
                        }
                        setThreeD(!threeD)
                    }} style={threeD ? {background: '#b4b6ba', color: 'black'} : {color: 'black'}}>
                        <FontAwesomeIcon icon={faCube} style={{fontSize: '16px'}}/>
                    </button>

                    <button onClick={() => {
                        if (jLine) {
                            map.setPaintProperty('line-layer', 'line-opacity', 0);
                            map.setLayoutProperty('journey-points-layer', 'visibility', 'none');
                            map.setLayoutProperty('events-layer', 'visibility', 'none');
                            map.setLayoutProperty('start-journey-layer', 'visibility', 'none');
                        } else {
                            map.setPaintProperty('line-layer', 'line-opacity', 1);
                            map.setLayoutProperty('journey-points-layer', 'visibility', 'visible');
                            map.setLayoutProperty('events-layer', 'visibility', 'visible');
                            map.setLayoutProperty('start-journey-layer', 'visibility', 'visible');
                        }
                        setJLine(!jLine)
                    }} style={jLine ? {background: '#b4b6ba', color: 'black'} : {color: 'black'}}>
                        <svg style={{width: '20px', height: '19px'}} xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512">
                            <path
                                d="M440 96C440 109.3 429.3 120 416 120C402.7 120 392 109.3 392 96C392 82.75 402.7 72 416 72C429.3 72 440 82.75 440 96zM416 256H336C309.5 256 288 277.5 288 304C288 330.5 309.5 352 336 352H432C476.2 352 512 387.8 512 432C512 476.2 476.2 512 432 512H139.6C147.3 503.2 156.5 492.3 165.1 480H432C458.5 480 480 458.5 480 432C480 405.5 458.5 384 432 384H336C291.8 384 255.1 348.2 255.1 304C255.1 259.8 291.8 224 336 224H387.1C358.8 190.3 320 136.7 320 96C320 42.98 362.1 0 416 0C469 0 512 42.98 512 96C512 140.3 466 199.9 437.7 232.4C425.1 246.9 416 256 416 256zM468.4 133.7C476.5 117.5 480 104.7 480 96C480 60.65 451.3 32 416 32C380.7 32 352 60.65 352 96C352 104.7 355.5 117.5 363.6 133.7C371.5 149.4 382.2 165.7 393.6 180.8C401.3 191.1 409.1 200.6 416 208.6C422.9 200.6 430.7 191.1 438.4 180.8C449.8 165.7 460.5 149.4 468.4 133.7zM71.1 352C71.1 338.7 82.74 328 95.1 328C109.3 328 119.1 338.7 119.1 352C119.1 365.3 109.3 376 95.1 376C82.74 376 71.1 365.3 71.1 352zM117.7 488.4C115.7 490.7 113.8 492.8 112.1 494.8L112 494.8C102.5 505.5 96 512 96 512C96 512 86.9 502.9 74.3 488.4C45.98 455.9 0 396.3 0 352C0 298.1 42.98 256 96 256C149 256 192 298.1 192 352C192 392.8 152.9 446.7 124.6 480.4C122.2 483.2 119.9 485.9 117.7 488.4L117.7 488.4zM148.4 389.7C156.5 373.5 159.1 360.7 159.1 352C159.1 316.7 131.3 288 95.1 288C60.65 288 31.1 316.7 31.1 352C31.1 360.7 35.53 373.5 43.62 389.7C51.46 405.4 62.25 421.7 73.6 436.8C81.34 447.1 89.11 456.6 95.1 464.6C102.9 456.6 110.7 447.1 118.4 436.8C129.7 421.7 140.5 405.4 148.4 389.7z"/>
                        </svg>
                    </button>

                </React.Fragment>


            }


        </div>
    )
}

export default ReactMapControls