import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const FatigueStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" style={{height, width: height, verticalAlign: 'middle'}} className={"tooltipTargetEventClass" + event?._id} data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95"  fill={'var(--surface-border)'}/>
                <path id="fatigue" style={{fill: 'var(--text-color)'}} d="m213.95,291.01c45.42-15.08,78.92-56.19,82.91-105.62,6.12-75.74-58.86-139.08-134.42-131.05-61.15,6.5-108.78,58.23-108.78,121.11,0,44.96,24.36,84.21,60.57,105.33-4.66-8.56-7.28-18.36-7.28-28.78v-41.15c0-13.65,11.09-24.74,24.74-24.74.86,0,1.71.05,2.52.14,7.37-15.41,23.07-26.02,41.25-26.02,13.65,0,25.93,5.99,34.3,15.51,7.04-1.14,14.46.95,19.89,6.38,5.8,5.8,7.85,13.99,6.09,21.41.52.43,1.05.9,1.52,1.43,8.61,8.61,8.9,22.41.86,31.35,8.04,8.94,7.75,22.74-.86,31.35l-23.31,23.31v.05Zm-62.63-188.87c2.62,3.28,2.09,8.09-1.19,10.71l-38.08,30.47c-3.28,2.62-8.09,2.09-10.71-1.19s-2.09-8.09,1.19-10.71l38.08-30.47c3.28-2.62,8.09-2.09,10.71,1.19Zm50.22,10.71c-3.28-2.62-3.81-7.43-1.19-10.71s7.43-3.81,10.71-1.19l38.08,30.47c3.28,2.62,3.81,7.43,1.19,10.71s-7.43,3.81-10.71,1.19l-38.08-30.47Zm-6.2,71.47c-3.03-1.9-7.03-1.24-9.56,1.3l-37.32,37.32c-2.38,2.37-6.51.71-6.51-2.71v-9.04c0-4.44-2.92-8.5-7.24-9.51-6.19-1.45-11.76,3.27-11.76,9.24v41.04c0,24.99,20.23,45.22,45.22,45.22,11.97,0,23.51-4.75,31.97-13.25l26.78-26.78c2.53-2.53,3.2-6.53,1.3-9.56-2.68-4.27-8.48-4.73-11.83-1.38l-9.06,9.06c-1.1,1.1-2.86,1.37-4.13.48-1.66-1.17-1.81-3.47-.44-4.84l24.16-24.16c2.53-2.53,3.2-6.53,1.3-9.56-2.68-4.27-8.48-4.73-11.83-1.38l-24.26,24.26c-1.1,1.1-2.86,1.37-4.13.48-1.66-1.17-1.81-3.47-.44-4.84l31.76-31.76c2.53-2.53,3.2-6.53,1.3-9.56-2.68-4.27-8.48-4.73-11.83-1.38l-31.86,31.86c-1.1,1.1-2.86,1.37-4.13.48-1.66-1.17-1.81-3.47-.44-4.84l24.37-24.37c3.35-3.35,2.89-9.15-1.38-11.83Z" />
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Fatigue</Tooltip>
            }
        </div>
    )
}
export default FatigueStacked
