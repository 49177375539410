import React from 'react';
import {Tooltip} from "primereact/tooltip";



const CamerasCoveredStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id}  style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill="var(--surface-border)"/>
                <path d="m70.67,81.94c-3.87-3.05-9.48-2.34-12.53,1.52-3.05,3.87-2.34,9.48,1.52,12.53l220.19,172.58c3.87,3.05,9.48,2.34,12.53-1.52s2.34-9.48-1.52-12.53l-39.13-30.65c14.73-15.1,24.7-32.02,29.72-44.04,1.23-2.94,1.23-6.21,0-9.15-5.54-13.28-17.18-32.62-34.59-48.76-17.48-16.29-41.55-29.98-71.6-29.98-25.37,0-46.49,9.78-62.97,22.61l-41.62-32.62Zm56.16,44c13.46-9.56,29.64-16.14,48.43-16.14,24.25,0,44.19,11.01,59.47,25.18,14.32,13.32,24.21,29.12,29.27,40.28-4.69,10.41-13.61,24.85-26.37,37.53l-20.01-15.7c3.38-6.55,5.28-13.95,5.28-21.83,0-26.3-21.31-47.61-47.61-47.61-11.98,0-22.95,4.43-31.32,11.72l-17.15-13.43h0Zm76.29,60.25l-30.31-23.77c1.56-3.16,2.45-6.77,2.45-10.53,0-2.05-.26-4.05-.74-5.95h.12c15.86,0,29.41,12.15,30.33,27.98.25,4.32-.42,8.47-1.85,12.26Zm3.5,48.03c-9.48,4.05-19.94,6.51-31.36,6.51-24.25,0-44.19-11.01-59.47-25.18-14.32-13.32-24.21-29.12-29.27-40.28,3.09-6.84,8-15.44,14.65-24.1l-14.02-11.05c-8.48,11.05-14.54,22.06-18.08,30.57-1.23,2.94-1.23,6.21,0,9.15,5.54,13.28,17.18,32.62,34.59,48.76,17.48,16.29,41.55,29.98,71.6,29.98,17.78,0,33.44-4.8,46.94-12.09l-15.58-12.27h0Zm-78.96-59.27c0,26.3,21.31,47.61,47.61,47.61,4.95,0,9.71-.74,14.21-2.16l-20.9-16.44c-8.74-2.01-16.03-7.89-19.97-15.73l-20.87-16.44c-.07,1.04-.11,2.08-.11,3.16h.04Z" style={{fill: 'var(--text-color)', strokeWidth: '0px'}}/>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill: 'none'}}/>
                </g>
                <g id="boundingbox-2" data-name="boundingbox">
                    <rect x="41.65" y="54.91" width="282.78" height="282.78" style={{fill: 'none'}}/>
                </g>
            </svg>
            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Camera Covered</Tooltip>
            }
        </div>
    )
}
export default CamerasCoveredStacked
