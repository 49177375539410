import React, {useContext, useEffect, useRef, useState} from 'react'
import fetchSnapshot from "../../../../../api/media/fetchSnapshot";
import {Button} from "primereact/button";
import {safeParse} from "../../../../../functions/formatting/safeParse";
import imageLoading from "../../../../../svgs/awaitingSnap.png";
import {faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter, faMicrochipAi} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import indexContext from "../../../../contexts/indexContext";
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";
import snapshotFailed from "../../../../../svgs/snapshotUnavailable.png"


const toDisplay = ['Ambulance', 'Police Car', 'Rural', 'Urban', 'Zebra Crossing', 'Bicycle', 'Bus', 'Car', 'Fire Truck',
    'Motorcycle', 'Moving Van', 'Person', 'Pickup Truck', 'Traffic Light', 'Truck', 'Mobile Phone'];

const confidenceLevel = 90;

const SnapshotWithAi = ({snapshot, aiData}) => {

    const {winWidth} = useContext(indexContext)
    const [ isMounted, setIsMounted ] = useState(true);
    const [boxes, setBoxes] = useState(null);
    const [image, setImage] = useState(null);
    const [imageSize, setImageSize] = useState(null);
    const [tags, setTags] = useState(new Set([]));
    const [tagsShown, setTagsShown] = useState(new Set([]));
    const [imgHover, setImgHover] = useState()
    const imgRef = useRef();
    const [fullscreen, setFullscreen] = useState(false);
    const [authorised, setAuthorised] = useState(true)
    const imgContainer = useRef()


    const handleImageLoad = () => {
        setImageSize({
            height: imgRef?.current?.getBoundingClientRect()?.height,
            width: imgRef?.current?.getBoundingClientRect()?.width
        });
    }

    const handleTagClick = (tag) => {

        document.querySelectorAll('.' + tag).forEach(box => {
           box.style.display === 'block' ? box.style.display = 'none' : box.style.display = 'block';
        });

        if(tagsShown.has(tag)){
            tagsShown.delete(tag);
            setTagsShown(new Set([...tagsShown]));
        } else {
            setTagsShown(new Set([...tagsShown, tag]));
        }
    }

    const setBoundingBoxes = () => {
        if (typeof aiData === 'object' && safeParse(aiData) && safeParse(aiData)?.length > 0 && imageSize){

            const theBoxes = [];

            safeParse(aiData)?.forEach((label, index) => {



                if (label?.Instances && label?.Instances.length > 0){
                    label.Instances.forEach(instance => {
                        let box = instance.BoundingBox

                        const item = {
                            label: label.Name,
                            confidence: instance.Confidence,
                            style: {
                                top: Math.round(box.Top * imageSize.height) + 'px',
                                left: Math.round(box.Left * imageSize.width) + 'px',
                                width: Math.round(box.Width * imageSize.width) + 'px',
                                height: Math.round(box.Height * imageSize.height) + 'px',
                                borderColor: 'red',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                position: 'absolute',
                                display: label.Confidence >= confidenceLevel && toDisplay.indexOf(label.Name) > -1 ? 'block' : 'none'
                            }
                        }


                        theBoxes.push(item);
                        // setTagsShown(new Set([...tagsShown, label.Name]));
                        // setTags(new Set([...tags, label.Name]));
                        tags.add(label.Name)

                        if (label.Confidence >= confidenceLevel && toDisplay.indexOf(label.Name)){

                            tagsShown.add(label.Name)
                        }

                    })
                }
            })
            setBoxes(theBoxes);
        }

    }



    useEffect(() => {

        if(isMounted && snapshot){

            fetchSnapshot(snapshot?.file, 'lrg').then(r => {
                if (r !== '401'){
                    setImage(URL.createObjectURL(r));
                    setAuthorised(true)
                } else {
                    setImage(snapshotFailed)
                    setAuthorised(false)
                }
            });

        }
        window.addEventListener('resize', function(event){
            if(imgRef?.current){
                handleImageLoad()
            }
        });
    }, [snapshot]);




    useEffect(() => {
        setBoundingBoxes()
    }, [imageSize, aiData])


    useEffect(() => {

        document.addEventListener('fullscreenchange', handleFsChange);

        return () => {
            URL.revokeObjectURL(image)
            document.removeEventListener('fullscreenchange', handleFsChange);

            setImage(null);
            setIsMounted(false);
        }
    }, []);


    const buttonStyling = (item) => {

        const tagStyle = {
            padding: '2px 2px',
            margin: '3px',
            background: 'red',
            color: 'white',
            fontSize: '11px',
            borderRadius: '3px'
        }

        const tagStyle2 = {
            padding: '2px 2px',
            margin: '3px',
            background: 'grey',
            color: 'white',
            fontSize: '11px',
            borderRadius: '3px'
        }

        const tagStyle3 = {
            padding: '2px 2px',
            margin: '3px',
            background: 'red',
            color: 'white',
            fontSize: '11px',
            borderRadius: '3px'
        }
        return tags.has(item.label) ? (tagsShown.has(item.label) ? tagStyle : tagStyle2) : tagStyle3
    }

    const addDefaultSrc = (e) => {
        e.target.src = imageLoading
    }


    const handleFsChange = (e) => {
        if (document.fullscreenElement || document.webkitFullscreenElement ||
            document.mozFullScreenElement) {
            setFullscreen(true);
        } else {
            setFullscreen(false)
        }
    }


    const toggleFullscreen = (val) => {
        setFullscreen(val);
        if (val){
            imgRef.current.style.maxHeight = '90vh';
            imgContainer?.current?.requestFullscreen();
        } else {
            imgRef.current.style.maxHeight = '45vh';
            document.exitFullscreen();

        }
    }


    const BoundingBoxButtons = () => {
        let buttons = []

        boxes.forEach(box => {
            if(buttons.filter(b => {return b.label === box.label}).length < 1 && box.confidence >= confidenceLevel && toDisplay.indexOf(box.label) > -1){
                buttons.push(box);
            }
        });


        return (
            <div style={{textAlign: 'center', margin: '0 auto'}}>
                {buttons.length > 0 && buttons?.map((item, index) =>
                    <span key={index}>
                        {item.label &&
                            <Button className="p-mb-2" onClick={() => handleTagClick(item.label)} style={buttonStyling(item)} disabled={!item.style}>
                                {item?.label } ({item?.confidence?.toFixed(2)}%)
                            </Button>
                        }
                    </span>
                )}
            </div>
        )
    }




    return (
        <center ref={imgContainer}>
                <div style={{position: 'relative', width: '100%'}}>
                    <div style={{position: 'relative', width: '100%'}}>
                        <img src={image} ref={imgRef} style={{width: '95%'}} onLoad={handleImageLoad}
                             onError={(e) => {addDefaultSrc(e)}}
                             onMouseEnter={() => setImgHover(true)} onMouseLeave={() => setImgHover(false)}
                             onClick={() => {
                                 if (winWidth > 800){
                                     toggleFullscreen(true)
                                 }
                             }}
                        />

                        {/*{imgHover && !fullscreen &&*/}
                        {/*    <button className="p-button" style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex:5000}}*/}
                        {/*            onMouseEnter={() => setImgHover(true)}*/}
                        {/*            >*/}
                        {/*        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />*/}
                        {/*        &nbsp;Fullscreen*/}
                        {/*    </button>*/}
                        {/*}*/}
                    </div>

                    {aiData !== null && boxes && boxes.length > 0 && fetchUserLevel() === 'superuser' && authorised &&
                        <div>
                            {/*<FontAwesomeIcon icon={faMicrochipAi} size="2x" color="red" style={{marginBottom: '5px'}}/>*/}
                            <BoundingBoxButtons/>
                        </div>
                    }

                    {fullscreen &&
                        <center>
                            <br />
                            <button onClick={() => toggleFullscreen(false)} className="p-button">
                                <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
                                &nbsp;Exit Fullscreen
                            </button>
                        </center>
                    }

                    {/*{aiData !== null && boxes && boxes.length > 0 && authorised && boxes.map((box, index) =>*/}
                    {/*    <div style={box.style} key={index} className={box.label}>&nbsp;</div>*/}
                    {/*)}*/}
                </div>
        </center>
    )
}
export default SnapshotWithAi


