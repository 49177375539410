import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const CorneringStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id}  style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill={'var(--surface-border)'}/>
                <path id="cornering" d="m160.95,223.41v40.82c-37.94-6.37-67.91-36.34-74.24-74.28h48.23l26,33.46Zm31,0l26-33.46h48.23c-6.32,37.94-36.29,67.91-74.24,74.24v-40.78Zm29.5-63.46l-3.38-6.7c-2.53-5.06-7.73-8.3-13.41-8.3h-56.44c-5.67,0-10.87,3.19-13.41,8.3l-3.37,6.7h-43.73c7.13-42.56,44.16-75,88.73-75s81.61,32.44,88.73,75h-43.73Zm-45,135.82c66.73,0,120.82-54.09,120.82-120.82s-54.09-120.82-120.82-120.82-120.82,54.09-120.82,120.82,54.09,120.82,120.82,120.82Z" style={{fill:'var(--text-color)'}}/>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill:'none'}}/>
                </g>
                <g id="boundingbox-2" data-name="boundingbox">
                    <rect x="41.65" y="54.91" width="282.78" height="282.78" style={{fill:'none'}}/>
                </g>
            </svg>


            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Cornering</Tooltip>
            }
        </div>
    )
}
export default CorneringStacked
