import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const LaneDepartureStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id}  style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill={'var(--surface-border)'}/>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill:"none"}}/>
                </g>
                <g id="boundingbox-2" data-name="boundingbox">
                    <rect x="41.65" y="54.91" width="282.78" height="282.78"  style={{fill:"none"}}/>
                </g>
                <path d="m131.99,78.95c-13.03,0-24.64,8.14-29.01,20.4l-46.89,130.29c-7.24,20.1,7.63,41.31,29.01,41.31h181.46c21.39,0,36.26-21.21,29.01-41.31l-46.89-130.29c-4.41-12.26-16.03-20.4-29.01-20.4h-87.69Zm-9.88,28c1.5-4.18,5.5-7,9.95-7h32.89v10c0,4.86,3.26,9.26,8.01,10.32,6.91,1.53,12.99-3.52,12.99-10.02v-10.3h34.26c4.42,0,8.4,2.7,9.89,6.82l47.86,130.41c2.45,6.69-2.62,13.77-9.89,13.77h-82.12v-9.97c0-4.87-3.26-9.26-8.01-10.32-6.91-1.53-12.99,3.52-12.99,10.01v10.28h-82.12c-7.26,0-12.34-7.07-9.89-13.75l47.86-130.25h1.3Zm63.84,50.62c0-6.64-6.1-11.81-13.04-10.21-4.72,1.09-7.96,5.46-7.96,10.3,0,8.73,0,26.08,0,34.81,0,4.85,3.24,9.21,7.96,10.3,6.93,1.6,13.04-3.56,13.04-10.21v-35Z" style={{fill: "var(--text-color)"}}/>
            </svg>


            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Lane Departure</Tooltip>
            }
        </div>
    )
}
export default LaneDepartureStacked
