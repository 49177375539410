import React, {useContext, useEffect, useRef, useState} from 'react';
import videoUploadProgress from "./requestingMediaFunctions/videoUploadProgress";
import ImageMfl from "../viewMedia/ImageMfl";
import fetchEventsVideoFile from "../../../api/events/fetchEventsVideoFile";
import videoPlay from "../../../svgs/video_play.png";
import deleteNotificationMediaQueue from "../../../api/notifications/deleteNotificationMediaQueue";
import mainContext from "../../contexts/mainContext";
import {uploadTrackStageDisplay} from "./requestingMediaFunctions/uploadTrackStageDisplay";
import {Knob} from "primereact/knob";
import VideoThumb from "../viewMedia/videoThumb";
import FetchMediaToken from "../../../api/media/fetchMediaToken";
import {API_URL, NOTIFICATIONS_CALLBACK_URL, TOAST_DURATION} from "../../../Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faArrowsRotate, faArrowRight, faMicrochip, faX} from "@fortawesome/pro-light-svg-icons";
import postVideoRerequest from "../../../api/media/postVideoRerequest";
import {Tooltip} from "primereact/tooltip";
import {faCircleInfo} from "@fortawesome/pro-light-svg-icons";
import {getSecondsDifference} from "../../../functions/formatting/formatDate";
import {findDataByKey} from "../../../functions/db";
import postLogReRequest from "../../../api/notifications/postLogReRequest";
import gridContext from "../../contexts/gridContext";
import postVideoRequest from "../../../api/media/postVideoRequest";
import fetchVideoDetails from "../../../api/media/fetchVideoDetails";


const VideoUploadProgressTrackerV2 = ({upload, page, getMediaQueue}) => {

    const {handleEvent, handleRefreshNotifications, vehicles, event, toast, deviceDetails} = useContext(mainContext);


    const [uploadProgress, setUploadProgress] = useState({stage: 'pending', percentage: 0});
    const [complete, setComplete] = useState(false);
    const [theEvent, setTheEvent] = useState(event);
    const [mounted, setMounted] = useState(true);
    const [contentSrc, setContentSrc] = useState();
    const [stageOfUpload, setStageOfUpload] = useState('uploading');
    const [uploadDetails, setUploadDetails] = useState('Awaiting start');
    const [reRequest, setReRequest] = useState(upload?.reRequest);
    const [deviceOnline, setDeviceOnline] = useState(true)

    const intervalRef = useRef();

    const responseArr = useRef([]);


    const handleResponse = async (data) => {

        let response = 'done';

        response = await postVideoRerequest(data);

        toast?.current?.show({ severity: 'success', summary: 'Upload', detail: response, life: TOAST_DURATION });
    }


    const retryUpload = async (e) => {

        const intDevice = await findDataByKey('vehicles', upload?.dn);

        if (getSecondsDifference(upload?.requestDate, new Date()) / 86400 < 7){
            e.stopPropagation()
            const data = {
                dn: upload.dn,
                mediaId: upload.media_id,
                requestType: 'keep_trying'
            }

            await postLogReRequest({upload: upload, reRequest: {type: 'upload', date: new Date(), user: localStorage.getItem('email')} });
            await handleResponse(data);
            setReRequest({type: 'upload', date: new Date(), user: localStorage.getItem('email')})

        } else {
            // delete request to setup again and retry

            await postVideoRerequest({
                dn: upload.dn,
                mediaId: upload.media_id,
                requestType: 'cancel'
            });

            await deleteNotificationMediaQueue(upload);

            const videoToRequest = {
                st: upload?.st,
                et: upload?.et,
                dn: upload?.dn,
                ch: upload?.channel,
                callback: NOTIFICATIONS_CALLBACK_URL,
            };

            await postVideoRequest(videoToRequest, intDevice, false, upload?.geometry,
                {type: 'resetUpload', date: new Date(), user: localStorage.getItem('email')});
        }
    }

    const deleteMediaQueue = async () => {

        const data = {
            dn: upload.dn,
            mediaId: upload.media_id,
            requestType: 'cancel'
        }

        if (!data?.mediaId){
            const details = await fetchVideoDetails(upload?.file);
            data.mediaId = details?._id
        }

        if (event) data.eventId = event._id


        await deleteNotificationMediaQueue(upload);
        await handleResponse(data);

        if (intervalRef?.current) clearInterval(intervalRef?.current);
        const ele = document.getElementById(upload?._id);
        if (ele) ele.style.display = 'none';

    }


    const processUpload = async (e) => {

        //TODO check if request is less than a week old, if older then delete and re-request

        e.stopPropagation();
        const data = {
            dn: upload.dn,
            mediaId: upload.media_id,
            requestType: 'process_mp4'
        }
        await postLogReRequest({upload: upload, reRequest: {type: 'process', date: new Date(), user: localStorage.getItem('email')} });
        await handleResponse(data);
        setReRequest({type: 'process', date: new Date(), user: localStorage.getItem('email')})

    }


    const retryMessageClass = () => {
        if (reRequest?.date){
            let pre = uploadProgress?.details?.requestResponse?.length - 1
            let index =  pre >= 0  ? pre : 0;
            if (uploadProgress?.details?.requestResponse?.length > 0){
                if (new Date(reRequest?.date) > new Date(uploadProgress?.details?.requestResponse[index]?.errTime)){
                    return 'p-inline-message p-inline-message-info'
                } else {
                    return 'p-inline-message p-inline-message-error'
                }
            } else {
                return 'p-inline-message p-inline-message-info'
            }
        }
    }




    useEffect( async () => {
        if (upload && vehicles && mounted) {

            let theProgress = await videoUploadProgress(upload);
            responseArr.current = [theProgress];


            if (theProgress){
                if (theProgress?.stage === 'error'){


                    // if(page !== 'trackModal'){
                    //     deleteNotificationMediaQueue(upload).then(() => {
                    //         getMediaQueue()
                    //     });
                    // }


                } else {
                    if (theProgress?.stage === 'processing' && (theProgress?.percentage) > 85){

                        // deleteNotificationMediaQueue(upload).then(() => {
                        //     getMediaQueue()
                        // });

                        clearInterval(intervalRef.current);
                        setUploadProgress(theProgress);
                        setUploadDetails('Finalising upload');
                        setComplete(true)

                    } else {
                        // waiting for upload
                        setUploadProgress(theProgress);
                        setUploadDetails('Please wait');
                        intervalRef.current = setInterval(async () => {
                            let theProgress = await videoUploadProgress(upload);

                            let arr = responseArr.current
                            arr.push(theProgress);

                            setUploadProgress(theProgress);
                        }, 5000);

                    }
                }
            } else {
                clearInterval(intervalRef.current);
            }
        }

        return () => {
            clearInterval(intervalRef.current);
        }
    }, [upload, vehicles])





    useEffect( async () => {



        if (uploadProgress.percentage > 0) {
            setStageOfUpload('paused');
        } else {
            setStageOfUpload('pending');
        }


        setStageOfUpload(uploadProgress.stage)

        let dn = upload?.dn ? upload?.dn : event?.dn;
        const intDevice = await findDataByKey('vehicles', dn);


        if (getSecondsDifference(intDevice?.properties?.lastHeartbeat, new Date()) > 120) {
            setUploadDetails('Device offline')
            setDeviceOnline(false)
            clearInterval(intervalRef.current);
        }

        // if failed for any reason stop the interval
        if ((uploadProgress?.details?.failed || uploadProgress?.details?.unavailable)){
            clearInterval(intervalRef.current);



            const footageNotOnDeviceErrCount = uploadProgress?.details?.requestResponse?.filter(resp => resp.err === '6');
            setStageOfUpload('failed');


            // if half or more responses are unavailable, assume footage is not available. Otherwise allow retry

            if (uploadProgress?.details?.requestResponse?.length > 20 && footageNotOnDeviceErrCount?.length <= 10) {
                setUploadDetails('Max attempts exceeded')
            } else if (footageNotOnDeviceErrCount?.length > (uploadProgress?.details?.requestResponse?.length * 0.75)) {
                setUploadDetails('Footage not on device')
            } else {
                setUploadDetails('Please try again');
            }

            return;
        }


        if (uploadProgress.stage === 'processing' && uploadProgress.percentage === 100){
            clearInterval(intervalRef.current);
            setComplete(true);
            console.log(uploadProgress)
            if (upload.st){
                const r = await fetchEventsVideoFile({
                    dn: uploadProgress.details.dn,
                    file: uploadProgress.details.filename
                });

                if(JSON.stringify(theEvent) !== JSON.stringify(r?.[0]) && r?.[0]){
                    setTheEvent(r?.[0]);
                }
            }

            if(page === 'trackModal' && mounted){
                handleRefreshNotifications();
            } else if (page === 'notifications'){
                deleteMediaQueue(upload)
            }
            return;
        }


    }, [uploadProgress]);







    useEffect(async () => {
        if (complete){
            const file = upload.file ? upload.file : upload.filename
            const token = await FetchMediaToken(file);
            setContentSrc(API_URL +'/view-video/' + file + '?mt=' + token?.access_token);

            if(page !== 'trackModal'){
                deleteNotificationMediaQueue(upload).then(() => {
                    getMediaQueue()
                });
            }
        }
    }, [complete])

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
            setMounted(false)
        }
    }, []);


    return (
        <React.Fragment>
            {complete ?
                <div style={{position: 'relative'}}>
                    <video src={contentSrc} controls={false} style={{width: '100%'}}></video>

                    <img src={videoPlay} onClick={() => {
                        handleEvent(theEvent)
                    }} style={{
                        position: 'absolute',
                        width: '100px',
                        cursor: 'pointer',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}/>
                </div>


            :
                <React.Fragment>
                    <Knob value={uploadProgress?.percentage?.toFixed(0)} valueTemplate={'{value}%'}/>

                    <div style={{marginTop: '10px'}}>
                        {uploadTrackStageDisplay(stageOfUpload)}<br />
                        {uploadDetails}<br />
                    </div>

                </React.Fragment>
            }

            {!complete && !upload?.startTimeHyper &&
                <div style={{textAlign: 'center', marginTop: '10px'}}>

                    {!reRequest?.date &&
                        <React.Fragment>
                            <span className={"deleteButton" + upload?.media_id} onClick={(e) => {
                                e.stopPropagation();
                                deleteMediaQueue(upload)
                            }} data-pr-position="bottom">
                                <FontAwesomeIcon icon={faX} style={{cursor: 'pointer', fontSize: "20px"}}/>
                            </span>
                            <Tooltip target={".deleteButton" + upload?.media_id}>Cancel upload</Tooltip>
                        </React.Fragment>

                    }



{/*is footage on device*/}


                    {!reRequest?.date && stageOfUpload === 'failed' &&
                        <React.Fragment>
                            <span className={"retryButton" + upload?.media_id} onClick={(e) => retryUpload(e)}
                                  data-pr-position="bottom" style={{marginLeft: '5px'}}>
                                <FontAwesomeIcon icon={faArrowsRotate} style={{cursor: 'pointer', fontSize: "22px"}}/>
                            </span>
                            <Tooltip target={".retryButton" + upload?.media_id}>Retry upload</Tooltip>
                        </React.Fragment>
                    }


                    {(getSecondsDifference(upload?.requestDate, new Date()) / 86400 < 7)  && (!reRequest && stageOfUpload === 'failed' && uploadProgress?.percentage > 10) &&
                        <React.Fragment>
                            <span className={"processBtn" + upload?.media_id} onClick={(e) => processUpload(e)}
                                  data-pr-position="bottom" style={{marginLeft: '5px'}}>
                                <FontAwesomeIcon icon={faMicrochip} style={{cursor: 'pointer', fontSize: "20px"}}/>
                            </span>
                            <Tooltip target={".processBtn" + upload?.media_id}>Process available footage to MP4</Tooltip>
                        </React.Fragment>
                    }


                    {reRequest &&
                        <div className={retryMessageClass()} style={{padding: '3px 5px'}}>
                           <span className="p-message-text">
                               {(reRequest.type === 'upload' || reRequest.type === 'resetUpload') && !retryMessageClass()?.includes('error') &&
                                   <React.Fragment>
                                       Retrying upload
                                       {!deviceOnline && <React.Fragment>&nbsp;when online</React.Fragment>}
                                   </React.Fragment>
                               }

                               {reRequest.type === 'process' &&
                                   <React.Fragment>
                                       Processing manually started
                                   </React.Fragment>
                               }

                               {retryMessageClass()?.includes('error') &&
                                <React.Fragment>
                                    Retry failed
                                </React.Fragment>
                               }

                               <span style={{marginLeft: '5px'}} className={"deleteButton" + upload?.media_id} onClick={(e) => {
                                   e.stopPropagation();
                                   deleteMediaQueue(upload)
                               }} data-pr-position="bottom">
                                <FontAwesomeIcon icon={faX} style={{cursor: 'pointer'}}/>
                            </span>
                            <Tooltip target={".deleteButton" + upload?.media_id}>Cancel upload</Tooltip>
                           </span>
                        </div>
                    }


                </div>
            }
        </React.Fragment>

    )
}

export default VideoUploadProgressTrackerV2
