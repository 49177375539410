import React, {useContext, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationArrow} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "primereact/tooltip";
import {faSquareParking} from "@fortawesome/pro-regular-svg-icons";
import {getTimeDifference} from "../../../functions/formatting/formatDate";
import mainContext from "../../contexts/mainContext";
import {faCompassSlash, faHeartPulse, faBell} from "@fortawesome/pro-light-svg-icons";
import VehicleLiveLocationV2 from "./vehicleLiveLocationV2";
import {truncate} from "../../../functions/formatting/truncate";
import EditTags from "./editTags";
import indexContext from "../../contexts/indexContext";
import {lastTrackTime} from "../../../functions/formatting/lastTrackTime";
import {Skeleton} from "primereact/skeleton";
import {getDrivingStatus} from "../../../functions/drivingStatus";


const VehicleListTemplate = ({d}) => {

    const {notifications, handlePageToShow} = useContext(indexContext);


    const {vehicle, handleVehicle} = useContext(mainContext);
    const [latestVehicle, setLatestVehicle] = useState(d);
    const [mouseOver, setMouseOver] = useState(false);
    const [showPopout, setShowPopout] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [health, setHealth] = useState(new Set([]));
    const [drivingStatus, setDrivingStatus] = useState(getDrivingStatus(vehicle?.properties?.lastHeartbeat,
        vehicle?.properties?.lastLocation?.ac, new Date().getTime()))

    const ref = useRef(null);

    const handleShowPopout = (val) => {setShowPopout(val)};
    const handleMouseOver = (val) => setMouseOver(val);

    const handleLatestVehicle = (val) => setLatestVehicle(val);


    const selectedStyle = {
        // background: 'grey',
        color: 'var(--v-list-button-text-selected)',
        paddingLeft: '8px',
        position: 'relative',
        zIndex: 1200,
        borderRadius: '2px 0px 2px 2px',
        background: 'var(--main-accent-colour)',
        height: '92px'
    }

    const vStyle = {
        position: 'relative',
        cursor: 'pointer',
        borderTop: '1px solid var(--surface-border)',
    }

    const tStyle = {
        padding: '2px 2px',
        margin: '3px',
        background: 'var(--surface-300)',
        fontSize: '11px',
        color: 'var(--text-color)'
    }


    const driving = {
        color: 'green'
    }

    const idling = {
        color: 'orange'
    }

    const parked = {
        color: 'red'
    }

    const overRun = {
        color: 'red'
    }

    const vehicleDetail = {
        fontcolor: 'black',
        fontSize: '13px',
        marginLeft: '38px',
    }

    const svgCont = {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        paddingLeft: '11px'
    }




    const formatAlertString = (set) => {
        let str = '';

        set.forEach(item => {
            if (str?.length > 1) str += ', '
            str += item.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
        })



        return str;

    }


    useEffect(async () => {
        if(d) {
            setLatestVehicle(d);
            setDrivingStatus((getDrivingStatus(d?.properties?.lastHeartbeat,
                d?.properties?.lastLocation?.ac, new Date().getTime())))
        }

        if (notifications && isVisible){

            let filtered = await notifications?.filter(noti => (d?.properties?.dn === noti?.dn && noti?.notificationType?.includes('health') &&
                !noti?.notificationRead?.reduce((acc, curVal) => acc.concat(curVal.email), []).includes(localStorage.getItem('email'))));

            if (filtered?.length > 0){
                const reduced = filtered?.reduce((acc, curVal) => acc.concat(curVal.notificationType), [])
                const theSet= new Set(reduced)
                theSet.delete("health")
                theSet.delete("cameraHealth")
                theSet.delete("unavailableCamera")
                setHealth(theSet);
            } else {
                setHealth(new Set([]))
            }
        }
    }, [latestVehicle, notifications, d, isVisible]);

    const observerRef = useRef()

    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Element is in view
                    setIsVisible(true);
                } else {
                    // Element is out of view
                    setIsVisible(false);
                }
            },
            {
                threshold: 0.1, // Adjust this threshold as needed
            }
        );

        if (ref.current) {
            observerRef?.current?.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observerRef?.current?.unobserve(ref.current);
            }
        };
    }, []);

    useEffect(() => {
        let timeoutId;

        if (isVisible) {
            // Fire the function after 2 seconds of being visible
            timeoutId = setTimeout(() => {
                setVisible(true)
            }, 1000);
        } else {
            // Clear timeout if element is no longer visible
            clearTimeout(timeoutId);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isVisible]);


    useEffect(() => {
        if(visible){
            if (ref.current) {
                observerRef?.current?.unobserve(ref.current);
            }
        }
    }, [visible]);



    return (
        <div style={vStyle} key={d.properties.dn} ref={ref} id={"vList_"+ d.properties.dn}>

            {isVisible ?
                <div className="vehicleListEntry"
                     onMouseEnter={() => handleMouseOver(true)}
                     onMouseLeave={() => handleMouseOver(false)}
                     onClick={async (e) => {
                         await handleVehicle(d)
                     }}
                     style={latestVehicle?.properties?.dn === vehicle?.properties?.dn ? selectedStyle : {}}>

                    <div style={svgCont}>
                        {drivingStatus &&
                            <span style={eval(drivingStatus)}>
                          {(drivingStatus === 'driving') ?

                              <span className={"lastTrack" + d.properties.dn} data-pr-position="bottom" id={"lastTrack" + d.properties.dn}
                                    rotationnation={(latestVehicle?.properties?.lastLocation?.head - 45)}>
                                    <FontAwesomeIcon icon={faLocationArrow} size='lg'
                                                     transform={{rotate: (latestVehicle?.properties?.lastLocation?.head - 45)}}
                                    />
                                  <Tooltip target={".lastTrack" + d.properties.dn}>
                                  {lastTrackTime(latestVehicle, 'Last track was ', ' ago')}
                                  </Tooltip>
                              </span>


                              :
                              <React.Fragment>
                                  {latestVehicle?.properties?.lastLocation ?
                                      <span className={"parkedSince" + d.properties.dn} data-pr-position="bottom">
                                            <FontAwesomeIcon icon={faSquareParking} size="lg"
                                            />
                                            <Tooltip target={".parkedSince" + d.properties.dn}>
                                                {lastTrackTime(latestVehicle, 'Parked for ', '')}
                                            </Tooltip>
                                      </span>
                                      :
                                      <span className={"parkedSince" + d.properties.dn} data-pr-position="bottom">
                                            <FontAwesomeIcon icon={faCompassSlash} size="lg"
                                            />
                                            <Tooltip target={".parkedSince" + d.properties.dn}>
                                                Device missing
                                            </Tooltip>
                                      </span>
                                  }
                              </React.Fragment>

                          }
                      </span>
                        }
                    </div>


                    <div style={vehicleDetail}>
                    <span style={{fontSize: '14px'}}>

                        <span style={{fontWeight: 900}}>{latestVehicle?.properties?.registration}</span>
                        {latestVehicle?.properties?.driver &&
                            <React.Fragment>
                                &nbsp;&nbsp;
                                <div style={{background: 'var(--surface-border)', height: '14px', width: '1px', display: 'inline-block'}}></div>
                                &nbsp;&nbsp;
                                <div style={{fontSize: '12px', display:'inline-block'}}>{truncate(latestVehicle?.properties?.driver, 20, 18)}</div>
                            </React.Fragment>
                        }

                    </span>


                        <br/>

                        {latestVehicle?.properties?.lastLocation ?
                            <React.Fragment>
                                {(drivingStatus && drivingStatus == 'driving') &&
                                    <span>
                                        <strong>{(latestVehicle?.properties?.lastLocation?.speed / 100 * 0.62137119223733).toFixed(0)}</strong> mph&nbsp;&nbsp;
                                    </span>
                                }

                                {/*&nbsp;<FontAwesomeIcon icon={faClock} style={{verticalAlign: 'middle'}}/>&nbsp;*/}
                                {new Date(latestVehicle?.properties?.lastHeartbeat).toLocaleString('en-uk',
                                    {month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'})}


                                <div style={{maxWidth: '220px', overflow: 'hidden', marginLeft: '-1px'}}>
                                    {visible ?
                                        <VehicleLiveLocationV2 d={latestVehicle?.properties} icon={false} isVisible={visible}/>
                                        :
                                        <Skeleton  height="15px" />
                                    }
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                No GPS Data

                                <div style={{maxWidth: '220px', overflow: 'hidden', marginLeft: '-1px'}}>
                                    Location unknown
                                </div>
                            </React.Fragment>
                        }

                        <React.Fragment>


                            <EditTags d={d} handleShowPopout={handleShowPopout} handleMouseOver={handleMouseOver}
                                      handleLatestVehicle={handleLatestVehicle} mouseOver={mouseOver} showPopout={showPopout}/>
                        </React.Fragment>
                    </div>

                    {health?.size > 0 &&
                        <React.Fragment>


                            <div style={{paddingRight: '10px'}}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    await handlePageToShow('/notifications');
                                    await new Promise(r => setTimeout(r, 500));
                                    if (vehicle?.properties?.dn !== latestVehicle?.properties?.dn) {
                                        handleVehicle(latestVehicle)
                                    }

                                }} className={`${d?.properties?.dn}`} data-pr-position="bottom">
                                <FontAwesomeIcon icon={faBell} style={{color: 'red', fontSize: '22px'}}
                                                 className={'triangleIssue' + d?.properties?.dn}
                                                 data-pr-position="bottom" id={'triangleIssue' + d?.properties?.dn}
                                />
                            </div>


                            <Tooltip target={".triangleIssue" + d?.properties?.dn}>
                                Health alert
                                - {health?.size} {health?.size > 1 ? 'issues requiring attention' : 'issue requires attention'}
                            </Tooltip>
                        </React.Fragment>
                    }

                </div>

                :
                <div style={{height: '92.5px'}}></div>
            }
        </div>
    )
}


export default VehicleListTemplate
