import React, {useContext, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faTrashSlash} from "@fortawesome/pro-light-svg-icons";
import EventGridItemSkeleton from "./eventGridItemSkeleton";
import fetchSnapshot from "../../../../../api/media/fetchSnapshot";
import imageFailed from "../../../../../svgs/snapshotUnavailable.png";
import {Tooltip} from "primereact/tooltip";
import {faFastForward, faVideoCamera} from "@fortawesome/pro-regular-svg-icons";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import {dateString, formatDateNotificationsTable, formatDateOnly} from "../../../../../functions/formatting/formatDate";
import indexContext from "../../../../contexts/indexContext";
import mainContext from "../../../../contexts/mainContext";
import {API_URL, DmsEventTypes} from "../../../../../Constants";
import StackedSeverityClassificationIcon from "../cardComponents/stackedSeverity&ClassificationIcon";
import Star from "../cardComponents/star";
import Webfleet from "../../../../../svgs/webfleet.png";
import SelectedTick from "../../../../../svgs/eventClassificationIcons/selectedTick";
import HoverVideoPlayer from "../../../viewMedia/hoverVideoPlayer";
import VehicleLiveLocationV2 from "../../../vehicleList/vehicleLiveLocationV2";
import gridContext from "../../../../contexts/gridContext";
import isVisible from "../../../../../functions/isVisible";
import {Button} from "primereact/button";
import {findDataByKey} from "../../../../../functions/db";
import fetchJourneys from "../../../../../api/journeys/fetchJourneys";
import FetchMediaToken from "../../../../../api/media/fetchMediaToken";
import EventCardTags from "./eventCardTags";
import ImageMfl from "../../../viewMedia/ImageMfl";


const EventGridItem = ({event, unreadEvents, handleEvent, selected, eventCounts}) => {




    const {fleets, winWidth} = useContext(indexContext);
    const {vehicles, handleVehicle, fleetsToShow, handleFleetsToShow, displayEventClickModal, handleVideoToShow} = useContext(mainContext);
    const {handleNotificationRead} = useContext(gridContext);


    const [isLoaded, setIsLoaded] = useState(false);
    const [ testImage, setTestImage ] = useState();
    const [hover, setHover] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [vehicle, setVehicle] = useState(event);
    const [hlStartTrack, setHlStartTrack] = useState();

    const ref = useRef();
    const visible = isVisible(ref);

    const timer = useRef();

    const [mediaBoxStyle, setMediaBoxStyle] = useState({
        padding: '10px 0px 5px 0px',
        borderRadius: 'inherit',
        width: '100%',
        height: '100%',
        cursor: 'pointer'

    });

    const tStyle = {
        padding: '2px 2px',
        margin: '3px',
        background: 'var(--surface-300)',
        fontSize: '11px',
        color: 'var(--text-color)',
        border: "none"
    }

    const handleLoaded = () => {
        setIsLoaded(true)
    }

    const addDefaultSrc = (e) => {
        e.target.src = imageFailed
    }


    const viewJourney = () => {
        //
        // handleDate(new Date(event?.startTime));
        // const time = event?.startTime;
        // const v = vehicles?.features.filter(v => v?.properties?.dn === event?.dn)[0];
        // handleVehicle(v, time);
        // handlePageToShow('/map');

    }

    const handleClick = (e) => {
        e.stopPropagation();

        if (event?.eventTags?.includes("hyperlapse")){
            handleVideoToShow(event?.hyperlapse?.[0])
        } else {
            handleEvent(event);
        }


    }


    const handleHover = () => {
        setShowButtons(true)
        timer.current = setTimeout(() => {
            setHover(true)
        }, 500);
    }

    const handleHoverOut = () => {
        setShowButtons(false)
        clearTimeout(timer.current)
        setHover(false)
    }


    useEffect(() => {
        if(event && selected){
            if (selected.has(event?._id)){
                setMediaBoxStyle({
                    padding: '10px 0px 5px 0px',
                    borderRadius: '3px',
                    width: '100%',
                    height: '100%',
                    background: 'var(--card-selected)',
                    cursor: 'pointer'
                })
            } else {
                setMediaBoxStyle({
                    padding: '10px 0px 5px 0px',
                    borderRadius: '3px',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer'
                });
            }
        }

    }, [selected, event])




    useEffect(async () => {
       if (event && visible){
           let frontChn;
           let driverChn;
           let file;
           let failover;


           if (event?.cameras && event?.cameras?.length > 0 && !event.eventTags.includes('hyperlapse')){


               for await (const cam of event?.cameras){
                   if (cam.camPosition.toLowerCase() === 'front'){
                       frontChn = cam.channel
                   }

                   if (cam.camPosition.toLowerCase() === 'driver'){
                       driverChn = cam.channel
                   }
               }

               if (event?.snapshots?.length > 0){
                   for await (const snap of event?.snapshots){
                       if (snap.status !== 'unavailable' ){
                           failover = snap.file;
                       }



                       if (event?.eventSource.toLowerCase() === 'dms-adas' && DmsEventTypes.includes(event?.eventType.toLowerCase()) &&
                           snap?.ch?.toString() === driverChn?.toString() && snap.status !== 'unavailable'){
                           file =  snap.file
                       } else if (snap?.ch?.toString() === frontChn?.toString() && snap.status !== 'unavailable'  && !file){
                           file =  snap.file
                       }
                   }
               }
           } else {
               file = event?.snapshots?.[0].file
           }



           if (!event?.eventTags?.includes('hyperlapse')){
               await new Promise(r => setTimeout(r, 200));
               try {
                   if (file){
                       const img = await fetchSnapshot(file, 'sm');
                       await new Promise(r => setTimeout(r, 100));
                       setTestImage(URL.createObjectURL(img));
                   } else if (failover){
                       const img = await fetchSnapshot(failover, 'sm');
                       setTestImage(URL.createObjectURL(img));
                   } else {
                       setTestImage(imageFailed);
                   }
               } catch (e) {
                   setTestImage(imageFailed);
               }
           }


       } else if (event?.eventTags?.includes('hyperlapse')){
           const v = await findDataByKey('vehicles', event?.dn)
           setVehicle(v?.properties);
           const date = formatDateOnly(event?.startTime);
           const journeys = await fetchJourneys(event?.dn, date.split('-').reverse().join('-'));

           const time = new Date(event?.startTime).getTime();
           let theJ;

           for (let i = 0; i < journeys?.length; i++) {
               let j = journeys[i];

               if (new Date(j?.startTime).getTime() <= time &&  new Date(j?.endTime).getTime() >= time) {
                   setHlStartTrack(j.start)
                   break;
               }
           }


           const token = await FetchMediaToken(event?.hyperlapse?.[0]?.file);

           if (token !== '401'){
               setTestImage(API_URL +'/view-video/' + event?.hyperlapse?.[0]?.file + '?mt=' + token?.access_token);

           }

       }


        setTimeout(() => {handleLoaded()}, 1000)



    }, [event, visible]);


    useEffect(() => {
        return () => {
            URL.revokeObjectURL(testImage);
        }
    }, []);
    const scoreColourArr = ['green', '#ffc100', '#ff9a00', '#ff7000', '#ff4d00', '#ff0000']








    return (
        <div ref={ref} style={mediaBoxStyle} onMouseEnter={handleHover}
             onMouseLeave={handleHoverOut} >
            {isLoaded ?
                <React.Fragment>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '5px',
                        padding: '0px 10px 0px 10px'
                    }}>
                        <div style={{flex: 1, fontSize: '15px', fontWeight: 'bold'}}>
                            {formatDateNotificationsTable(event.startTime)}
                            <br/>
                            <div style={{fontSize: '12px', fontWeight: 'normal', lineHeight: '15px'}}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     handleVehicle(vehicles.features.filter(v => v.properties.dn === event.dn)?.[0])
                                 }} className={"reg" + event._id} data-pr-position="bottom">
                                {regFromDn(vehicles, event?.dn)}
                            </div>
                            <Tooltip target={".reg" + event._id} id={'reg' + event?._id}>
                                Click to filter by this vehicle
                            </Tooltip>
                        </div>


                        {showButtons &&



                            <React.Fragment>

                                <div style={{paddingLeft: '4px',lineHeight: '29px', width: '25px', height: '25px', borderRadius:'50%', background: 'var(--surface-border)'}}>
                                    {unreadEvents ?
                                        <Tooltip target={".markReadButton" + event._id}
                                                 id={'delete' + event?._id}>Delete</Tooltip>
                                        :
                                        <Tooltip target={".markReadButton" + event._id}
                                                 id={'delete' + event?._id}>Undelete</Tooltip>
                                    }
                                    <span className={"markReadButton" + event._id} data-pr-position="bottom"
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              handleNotificationRead(e, event, unreadEvents ? 'read' : 'unread')
                                          }}>

                                         {unreadEvents ?
                                             <FontAwesomeIcon icon={faTrash} style={{
                                                 cursor: 'pointer',
                                                 fontSize: "18px",
                                                 marginRight: '5px'
                                             }}/>
                                             :
                                             <FontAwesomeIcon icon={faTrashSlash} style={{
                                                 cursor: 'pointer',
                                                 fontSize: "18px    ",
                                                 marginRight: '5px'
                                             }}/>
                                         }

                                    </span>
                                </div>


                                {/*<div style={{paddingLeft: '2.5px',lineHeight: '29px', width: '25px', height: '25px', borderRadius:'50%', background: 'var(--surface-border)'}} onClick={(e) => {*/}
                                {/*    e.stopPropagation();*/}
                                {/*    handleNotificationStarred(event, event?.starredBy?.includes(event._id) ? "unstar" : "star")*/}
                                {/*}}>*/}
                                {/*    <Star data={event} starred={starred}/>*/}
                                {/*</div>*/}

                            </React.Fragment>
                        }

                        <div>
                            {selected.has(event?._id) ?
                                <SelectedTick height={'25px'}/>
                            :
                                <StackedSeverityClassificationIcon event={event} size={'25px'}/>
                            }
                        </div>

                        <div style={{
                            display: 'inline-block',
                            textAlign: 'center',
                            borderRadius: '50%',
                            width: '25px',
                            height: '25px',
                            lineHeight: '25px',
                            color:'white',
                            background: scoreColourArr?.[Math.floor((event?.riskScore / 20)) > -1 ? Math.floor((event?.riskScore / 20)) > 5 ? 5 : Math.floor((event?.riskScore / 20)) : 0]
                        }}>
                            {Math.floor((event?.riskScore / 10)) > -1 ? Math.floor(((event?.riskScore > 100 ? 100 : event?.riskScore) / 10)) : 0}
                        </div>


                    </div>


                    <div style={{textAlign: 'center', margin: '0 auto', marginTop: '15px', position: 'relative'}}>

                        {hover && (event?.videos?.length > 0) && !displayEventClickModal && winWidth > 800 &&
                            <HoverVideoPlayer video={event?.hyperlapse ? event?.hyperlapse?.[0] : event?.videos?.[0]} handleClick={handleClick}/>
                        }

                        {(event?.eventTags?.includes('hyperlapse') && winWidth > 800) ?
                            <video src={testImage} style={{
                                width: '100%',
                                borderRadius: '3px',
                                aspectRatio: '16/9'
                            }} onClick={handleClick} loop controlsList="nodownload" muted={true} autoPlay={false}></video>
                            :

                            <div style={{
                                width: '100%', aspectRatio: '16/9', display: 'flex',
                                alignItems: 'center', justifyContent: 'center'
                            }}>
                                {/*<ImageMfl file={testImage} size="sm" />*/}
                                <img src={testImage} alt="" className="product-image" width="100%"
                                     onError={(e) => {
                                         addDefaultSrc(e)
                                     }} onClick={handleClick}/>
                            </div>
                        }


                        <div style={{position: 'absolute', bottom: '6%', right: '2%', display: 'flex', gap: '5px'}}>
                            {event?.videos?.filter(vid => vid.status === 'uploaded')?.length > 0 &&
                                <div style={{
                                    background: 'var(--surface-border)', height: '30px', width: '30px', color: 'var(--text-color)',
                                        borderRadius: '50%', textAlign: 'center', lineHeight: '30px'
                                    }} className={`videos${event?._id}`} data-pr-position={"bottom"}>
                                        <FontAwesomeIcon icon={faVideoCamera}/>
                                        &nbsp;{event?.videos?.filter(vid => vid.status === 'uploaded')?.length}
                                    </div>

                                }


                                {!event?.eventTags?.includes('hyperlapse') &&
                                    <div style={{
                                        background: 'var(--surface-border)',
                                        height: '30px',
                                        width: '30px',
                                        color: 'var(--text-color)',
                                        borderRadius: '50%',
                                        bottom: '6%',
                                        right: '2%',
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        lineHeight: '9px',
                                        paddingTop: '5px',
                                    }} className={`speeding${event?._id}`} data-pr-position={"bottom"}>
                                        {event?.eventType === 'speeding' ?
                                            <React.Fragment><b>{event?.analysis?.speeding?.[0]?.maxSpeed.toFixed(0)}</b><div>mph</div></React.Fragment>
                                        :
                                            <React.Fragment><b>{ event?.speed > 0 ? (event?.speed / 100 * 0.621371).toFixed(0) : 0}</b> <div>mph</div></React.Fragment>
                                        }

                                    </div>


                                }

                            </div>


                        {event?.eventSource === 'webfleet' &&

                            <div style={{
                                position: 'absolute',
                                background: 'var(--surface-border)',
                                height: '30px',
                                width: '30px',
                                color: 'var(--text-color)',
                                borderRadius: '50%',
                                bottom: '6%',
                                left: '2%',
                                textAlign: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img src={Webfleet} style={{width: '25px'}}/>
                            </div>

                        }

                        {event?.eventTags?.includes('hyperlapse') &&

                            <div style={{
                                position: 'absolute',
                                background: 'var(--surface-border)',
                                height: '30px',
                                width: '30px',
                                color: 'var(--text-color)',
                                borderRadius: '50%',
                                bottom: '6%',
                                right: '2%',
                                textAlign: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <FontAwesomeIcon icon={faFastForward} />
                            </div>

                        }


                    </div>

                    <Tooltip
                        target={".videos" + event?._id}>{event?.videos?.length > 1 ? `${event?.videos?.length} videos` : "1 video"} uploaded
                        for event</Tooltip>

                    <Tooltip target={".speeding" + event?._id}>
                        {event?.eventType === 'speeding' ?
                            <React.Fragment>Maximum speed of {event?.analysis?.speeding?.[0]?.maxSpeed.toFixed(0)} mph</React.Fragment>
                            :
                            <React.Fragment>Speed of {(event?.speed / 100 * 0.621371).toFixed(0)} mph</React.Fragment>
                        }


                    </Tooltip>

                    {/*<EventCardTags event={event} eventCounts={eventCounts}/>*/}


                    <div style={{
                        textAlign: 'left', position: 'relative',
                        zIndex: '10', margin: '0 auto', borderRadius: '2px', padding: '10px 10px 0px 10px'
                    }}
                         onClick={(e) => {
                             //e.stopPropagation();
                             //viewJourney()
                         }}>
                        <VehicleLiveLocationV2 d={event?.eventTags?.includes('hyperlapse') ? hlStartTrack : event} icon={false}/>
                    </div>

                    <div style={{
                        textAlign: 'left',
                        position: 'relative',
                        zIndex: '10',
                        margin: '0 auto',
                        borderRadius: '2px',
                        marginTop: '2px',
                        padding: '0px 10px 10px 10px'
                    }}
                         onClick={(e) => {
                             e.stopPropagation();
                             handleFleetsToShow(new Set([...fleetsToShow, vehicle?.fleetId]));
                         }}>
                        {friendlyFleetName(vehicle?.fleetId, fleets)}
                    </div>




                    {/*<div style={{*/}
                    {/*    textAlign: 'left',*/}
                    {/*    position: 'relative',*/}
                    {/*    zIndex: '10',*/}
                    {/*    margin: '0 auto',*/}
                    {/*    borderRadius: '2px',*/}
                    {/*    marginTop: '2px',*/}
                    {/*    padding: '0px 10px 10px 10px'*/}
                    {/*}}>*/}
                    {/*    {event?.eventTags.map((t) => (*/}
                    {/*           <React.Fragment>*/}
                    {/*               {!['high speed', 'moderate g-force', 'high g-force', 'health'].includes(t) &&*/}
                    {/*                   <Button style={tStyle} key={t} label={t} className="p-mb-2"/>*/}
                    {/*               }*/}


                    {/*           </React.Fragment>*/}

                    {/*    ))}*/}
                    {/*</div>*/}
                </React.Fragment>
                :
                <div>
                    <EventGridItemSkeleton/>
                </div>
            }
        </div>
    )

};

export default EventGridItem

