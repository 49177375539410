import React from 'react';
import {Tooltip} from "primereact/tooltip";



const DeviceMissingStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id}  style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill="var(--surface-border)"/>
                <path d="m137.93,112.65c-4.12,3.66-10.44,3.25-14.11-.87s-3.25-10.44.87-14.11c20.56-18.1,47.56-29.09,77.07-29.09s56.51,10.99,77.02,29.09c4.12,3.66,4.54,9.95.87,14.11-3.66,4.16-9.95,4.54-14.11.87-16.98-15.02-39.32-24.09-63.79-24.09s-46.81,9.07-63.83,24.09Zm-62.99,108.95v33.68c0,3.66,3,6.66,6.66,6.66h186.68c3.66,0,6.66-3,6.66-6.66v-33.68c0-3.66-3-6.66-6.66-6.66H81.6c-3.66,0-6.66,3-6.66,6.66Zm-20-.03c0-14.71,11.92-26.63,26.63-26.63h110.37v-23.01c0-5.02,3.58-9.5,8.56-10.18,6.19-.85,11.44,3.88,11.44,9.89v23.3h56.37c14.71,0,26.63,11.92,26.63,26.63v33.74c0,14.71-11.92,26.63-26.63,26.63H81.58c-14.71,0-26.63-11.92-26.63-26.63v-33.74Zm43.62,6.79c5.52,0,9.99,4.47,9.99,9.99s-4.47,9.99-9.99,9.99-9.99-4.47-9.99-9.99,4.47-9.99,9.99-9.99Zm23.3,9.99c0-5.52,4.47-9.99,9.99-9.99s9.99,4.47,9.99,9.99-4.47,9.99-9.99,9.99-9.99-4.47-9.99-9.99Zm79.89-103.2c-11.9,0-22.84,4.16-31.42,11.11-4.29,3.45-10.57,2.83-14.06-1.46s-2.83-10.57,1.46-14.06c12.07-9.74,27.38-15.56,44.03-15.56s31.96,5.83,43.98,15.56c4.29,3.45,4.95,9.78,1.46,14.06s-9.78,4.95-14.06,1.46c-8.53-6.95-19.47-11.11-31.38-11.11Z" style={{fill: 'var(--text-color)'}} />
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill: 'none'}}/>
                </g>
                <g id="boundingbox-2" data-name="boundingbox">
                    <rect x="41.65" y="54.91" width="282.78" height="282.78" style={{fill: 'none'}}/>
                </g>
            </svg>


            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Device Missing</Tooltip>
            }
        </div>
    )
}
export default DeviceMissingStacked
