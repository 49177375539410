import React, {useContext, useEffect, useState} from 'react';
import {Dialog} from "primereact/dialog";
import jStartFlag from "../../../../svgs/mapIcons/jStartFlag.png";
import jEndedFlag from "../../../../svgs/mapIcons/jEndedFlag.png";
import JinProgress from "../../../../svgs/jInProgressArrow.png";
import TrackModal from "./trackModal";
import mainContext from "../../../contexts/mainContext";
import indexContext from "../../../contexts/indexContext";
import {formatDateModalHeader} from "../../../../functions/formatting/formatDate";

const TrackModalParent = ({track, handleHyperlapseLine, handleSelectedPoint, handleTrack}) => {


    const {displayTrackClickModal, closeModal, journey, deviceDetails, vehicle, journeys, date, calendar, handleRecenterMap} = useContext(mainContext)
    const {winWidth} = useContext(indexContext)

    const [ trackModalHeaderType, setTrackModalHeaderType ] = useState();
    const [ trackModalHeaderTime, setTrackModalHeaderTime ] = useState();



    useEffect(() => {
        if (track && journey){
            const jEnd = journey?.details.completed ?
                journey?.details.completed && journey?.features[journey?.features.length - 1]?.properties?.time === track?.properties?.time
                : false;

            const jStart = journey?.features[0]?.properties?.time === track?.properties?.time;

            setTrackModalHeaderTime(formatDateModalHeader(track?.properties?.time ? track?.properties?.time :
                track?.features?.[0]?.properties?.time)   );
            setTrackModalHeaderType(jEnd ? "Journey End" : jStart ? "Journey Start" : "Tracking Point");
        }
    }, [track, journey])


    return (
        <Dialog visible={displayTrackClickModal} position={'right'} modal={false}
                style={{width: winWidth >= 800 ? '30%' : '100%',
                    height: winWidth >= 800 ? '90%' : '100%', maxHeight:'100%', maxWidth: '100%'}}
                onHide={() => closeModal('displayTrackClickModal')}>

            <div style={{marginTop: '-50px', position: 'absolute', fontWeight: 'bold', fontSize: '16px', width: '250px', display:'flex', alignItems: 'center'}}>

                {trackModalHeaderType === 'Journey Start' ?
                    <img src={jStartFlag} width="40px" height="40px"/>
                    :
                    <React.Fragment>
                        {trackModalHeaderType === 'Journey End' ?
                            <img src={jEndedFlag} width="40px" height="40px"/>
                            :
                            <img src={JinProgress} width="40px" height="40px" style={{transform: `rotate(${track?.properties?.head}deg)`}}/>
                        }
                    </React.Fragment>
                }


                <div>
                    {trackModalHeaderType}

                    <div style={{fontWeight: 'normal'}}>
                        {trackModalHeaderTime}
                    </div>
                </div>
            </div>

            <TrackModal feature={track?.features?.[0] ? track?.features?.[0] : track} latLng={track?.lngLat ? track?.lngLat : track?.geometry.coordinates} deviceDetails={deviceDetails}
                        closeModal={closeModal} journey={journey} handleHyperlapseLine={handleHyperlapseLine}
                        vehicle={vehicle} handleRecenterMap={handleRecenterMap} journeys={journeys} date={date}
                        calendar={calendar} handleSelectedPoint={handleSelectedPoint} handleTrack={handleTrack}/>

        </Dialog>
    )
}

export default TrackModalParent
